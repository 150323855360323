export default {
  common: {
    expected: "Venituri preconizate",
    view: "Vizualizare",
    confirm: "Confirmă",
    cancel: "Anulează",
    deposit: "Depozit",
    withdraw: "Retragere",
    noData: "Momentan nu există date disponibile",
    submit: "Trimite",
    modify: "Modificare",
    hand: "Mână",
    confirm_verify: "Confirmă validarea",
    next: "Pasul următor",
    complete: "Adoptat",
    delete: "Șterge",
    countryCode: "Codul țării",
    no_balance: "Soldul contului insuficient",
  },
  currency: {
    btcusdt: "Bitcoin",
    ethusdt: "Etere",
    ltcusdt: "Litecoin",
    xrpusdt: "Ripple (monedă)",
    adausdt: "Monedă Ada",
    eosusdt: "Monedă Pomelo",
    dotusdt: "Boca coin",
    trxusdt: "Monedă de câmp ondulat",
    xmrusdt: "Monedă Monroe",
    dogeusdt: "Înapoi",
    bnbusdt: "BNB",
    solusdt: "SOL",
    aptusdt: "Aptusdt",
    fx_saudusd: "AUD USD",
    fx_sgbpusd: "GBP USD",
    fx_seurusd: "Eurodolar",
    fx_snzdusd: "Dolar neozeelandez (NZD) USD",
    fx_susdcad: "USD CAD",
    fx_susdchf: "USD CHF",
    fx_susdjpy: "USD JPY",
    fx_saudcad: "AUD CAD",
    fx_saudchf: "AUD CHF",
    fx_saudjpy: "AUD JPY",
    fx_saudnzd: "AUD New Zealand Dollar (NZD)",
    fx_scadchf: "CAD Swiss Franc",
    fx_scadjpy: "Canadian yen",
    fx_schfjpy: "CHF JPY",
    fx_seuraud: "EUR AUD",
    fx_seurcad: "EUR CAD",
    fx_seurgbp: "Euro Pound",
    fx_seurjpy: "Euro Yen",
    fx_seurnzd: "Euro New Zealand dollar (NZD)",
    fx_sgbpaud: "GBP AUD",
    fx_sgbpchf: "GBP CHF",
    fx_sgbpjpy: "GBP JPY",
    fx_sgbpnzd: "GBP NZD",
    fx_scadnzd: "Dolari canadieni și neozeelandezi",
    hf_CL: "WTI New York Crude Oil",
    hf_OIL: "Petrolul brut Brent",
    hf_GC: "Aur New York",
    hf_SI: "Argint New York",
    hf_HG: "Cupru (US)",
    hf_NG: "U.S. Natural Gas",
    hf_CAD: "Cupru (chimie)",
    hf_AHD: "London Aluminum",
    HX_AAPL: "rodie",
    HX_BABA: "Alibaba, societatea de comerț electronic din RPC",
    HX_GOOG: "Google",
    HX_WMT: "Wal-Mart, Walmart (comerciant cu amănuntul)",
    HX_AMZN: "HX_AMZN",
    HX_TSLA: "Tesla",
    HX_N225: "Nikkei 225",
    HX_GDAXI: "DAX30, Germany",
    HX_IBEX: "IBEX35, Spain",
    HX_SPX: "S&P 500 index",
    HX_FTSE: "FTSE 100",
    HX_KS11: "KOSPI Korea",
    HX_NZ50: "New Zealand 50",
    HX_PSI20: "Portugal PSI20",
    hf_ZSD: "London Zinc",
    hf_NID: "London Nickel",
    hf_PBD: "London lead",
    hf_SND: "London tin",
    hf_XAU: "London Gold (Spot Gold)",
    hf_XAG: "London Silver (Spot Silver)",
    hf_XPT: "Contracte futures cu platină",
    hf_XPD: "Palladium Futures",
    hf_CT: "US Cotton",
    hf_SM: "Făină de soia americană",
    hf_BO: "U.S. soybean oil",
    HX_SENSEX: "SENSEX, Mumbai, India",
    HX_KLSE: "FTSE Malaysia KLCI",
    HX_KSE100: "Karachi, Pakistan",
    HX_FCHI: "CAC40, France",
    HX_SX5E: "European Snooker 50",
    HX_ICEXI: "ICEX, Iceland",
    HX_ASE: "ASE Athens, Greece",
    HX_OMXC20: "OMX Copenhagen 20",
    HX_OSEBX: "OSEBX, Norway",
    HX_OMXSPI: "OMXSPI, Sweden",
    HX_AMD: "Chaowei Semiconductor",
    HX_DIS: "Disney (name)",
    HX_MELI: "MercadoLibre Inc",
    HX_PSX: "Phillips 66",
    HX_TRUE: "TrueCar Inc",
    HX_SDRL: "Seadrill Ltd",
    HX_MA: "MasterCard (brand)",
    HX_NVDA: "NVIDIA, computer graphics card company",
    HX_INTC: "Intel",
    HX_GOGO: "Gogo Inc",
    HX_STX: "Seagate Technology",
    usdcusdt: "USDC",
    filusdt: "FIL",
    maticusdt: "MATIC",
    linkusdt: "LINK",
    avaxusdt: "AVAX",
    atomusdt: "AstroCoin",
    icpusdt: "ICP",
    uniusdt: "UNI",
  },
  currenctType: {
    title: "Vă rugăm să selectați o monedă",
    currenctType6: 'USDT-ERC20',
    currenctType7: 'USDT-TRC20',
    currenctType8: 'BTC',
    currenctType13: 'ETH',
    currenctType14: 'Dollar',
    currenctType15: 'Hong Kong currency',
    currenctType16: 'Taiwan currency',
    currenctType17: 'Ringgit',
    currenctType18: 'Singapore dollar',
    currenctType19: 'Yen',
    currenctType20: 'Euro',
    currenctType21: 'Dollar A',
    currenctType22: 'Rupiah',
    currenctType23: 'Liră',
    currenctType24: 'Thai baht',
    currenctType25: 'Dolar',
    currenctType26: 'VND',
    currenctType27: 'Korean won',
    currenctType28: 'MOP',
  },
  addnew3: {
    direction: "Direcție",
    sell_price: "Prețul de vânzare",
    number: "Cantitate",
    type: "Timp de livrare",
    buy_price: "Prețul de cumpărare",
    profit: "P/L",
    sell_time: "Timp de vânzare",
  },
  addnew2: {
    address: {
      second_name: "Katakana",
      account_type: "Tipul contului",
      ifsc_code: "IFSC CODE",
      title: "Adresa portofelului",
      account_number: "Numărul contului",
      add_btn: "Adaugă adresă",
      label_type: "Selectează tipul",
      place_type: "Vă rugăm să selectați tipul",
      label_number: "Cont",
      place_number: "Vă rugăm să introduceți numărul contului",
      wallet_address: 'Adresa portofelului',
      bank_name: 'NUMELE BANCII',
      bank_address: "Adresa băncii",
      name: "Denumire",
      payee_address: 'Adresa beneficiarului',
      swift_code: "Swift/Swift Code/BIC",
      aba: "ABA",
      branch_number: "Numele sucursalei",
      branch_code: "CODUL BRANCH",
      bank_code: "Codul bancar",
      phone: "Număr de telefon mobil",
      iban: "IBAN",
      sort_code: "Cod de sortare",
      place_inner: "Introduceți conținutul",
    },
  },
  addnew: {
    realFirst: "Vă rugăm să efectuați autentificarea identității mai întâi",
    payFirst: "Vă rugăm să setați parola tranzacției mai întâi",
    password1: "Schimbă parola de autentificare",
    password2: "Schimbă parola de retragere",
    paypassTit1: "Vă rugăm să setați parola de retragere",
    oldPass: "Parolă veche",
    allView: "Toate citite",
    confirmOrder: "confirma o comandă",
    name: "Denumire",
    fee: "Taxă",
    orderCycle: "Calendar",
    loan: "Împrumut",
    loanTit: "Împrumuturi de asistență",
    loanHostory: "Înregistrările creditelor",
    labelProductTitle: "Denumirea produsului",
    immediateRepayment: "Rambursare imediată",
    loadAmount: "Valoarea preconizată a împrumutului",
    RepaymentCycle: "Ciclul de rambursare a creditelor",
    day: "Ziua",
    dailyRate: "Rata zilnică",
    repaymentMethod: "Metoda rambursării",
    lendingCompany: "Societate de împrumut",
    loanTip: "Credit credit credit (vă rugăm să vă asigurați că imaginea este clară și vizibilă)",
    loanTip1: "Încărcați certificatul de proprietate",
    loanTip2: "Dovada venitului (raport de muncă)",
    loanTip3: "Detalii extrasului bancar",
    loanTip4: "Încărcați o fotografie cu partea frontală a cardului dvs. de identitate",
    interest: "Dobândă",
    repaid: "Rambursare",
    unpaid: "Nepătit",
    tab1: "Crypto",
    tab2: "Contract",
    tab3: "Opțiune",
    available: "Limită disponibilă",
    pending: "Blocare",
    equivalent: "Echivalent",
    fundTransfer: "Transferul fondurilor",
    flashExchange: "Schimb flash",
    select_breed: "Selectează moneda",
    from: "De la",
    to: "Pentru",
    transferQuantity: "Cantitatea transferului",
    repaymentMethod1: "O rambursare la scadență"
  },
  params: {
    product_foreign: "Forex",
    product_shop: "Mărfuri",
    product_number: "Indici",
    product_stock: "Acțiuni",
    product_coin: "Crypto",
    billTypes: [
      { v: 101, name: 'Reîncărcare' },
      { v: 102, name: 'Depozit' },
      { v: 103, name: 'congela' },
      { v: 104, name: 'decongelare' },
      { v: 105, name: 'Depozit' },
      { v: 106, name: 'Depozit' },
      { v: 201, name: 'În așteptare' },
      { v: 202, name: 'Retragere' },
      { v: 203, name: 'Succes' },
      { v: 204, name: 'Retragerea a eșuat' },
      { v: 205, name: 'Taxa de gestionare a retragerilor' },
      { v: 206, name: 'Transfer afară' },
      { v: 207, name: 'Transfer în' },
      { v: 208, name: 'Transferul monedei' },
      { v: 209, name: 'Transferul monedei' },
      { v: 301, name: 'Taxa de gestionare a contractelor' },
      { v: 302, name: 'Venituri contractuale' },
      { v: 303, name: 'Pierderi contractuale' },
      { v: 304, name: 'Marja contractuală' },
      { v: 305, name: 'Rambursarea depozitului contractual' },
      { v: 311, name: 'Opțiuni de cumpărare' },
      { v: 312, name: 'Returnează opțiunea' },
      { v: 313, name: 'Rambursarea opțiunii' },
      { v: 314, name: 'Taxa de gestionare a opțiunilor' },
      { v: 315, name: 'Înghețarea cumpărăturilor valutare' },
      { v: 316, name: 'deducerea cumpărării valutare' },
      { v: 317, name: 'Returnarea cumpărării de monede' },
      { v: 318, name: 'Achiziționarea monedelor primite' },
      { v: 319, name: 'Achiziționarea monedelor primite' },
      { v: 320, name: 'Înghețarea vânzărilor valutare' },
      { v: 321, name: 'deducerea vânzărilor valutare' },
      { v: 322, name: 'Randamentul vânzărilor de monede' },
      { v: 323, name: 'Monede vândute în cont' },
      { v: 324, name: 'Monede vândute în cont' },
      { v: 325, name: 'Taxă de manipulare valutară' },
      { v: 401, name: 'Adăugarea mașinilor miniere' },
      { v: 402, name: 'Returnarea mașinilor miniere' },
      { v: 403, name: 'Venituri din utilaje miniere' },
      { v: 404, name: 'Ieșirea mașinii miniere' },
      { v: 405, name: 'Taxa de manipulare a ieșirii mașinilor miniere' },
      { v: 411, name: 'Venituri din împrumuturi' },
      { v: 412, name: 'Cost de închidere' },
      { v: 413, name: 'Rambursarea cu succes' },
    ],
    transType0: "Mărfuri spot",
    transType1: "Contract",
    directionType0: "Cumpără",
    directionType1: "Vânzare",
    lockStateType0: "Alergare",
    lockStateType1: "S-a terminat",
    lockStateType2: "Anulat",
  },
  lockming: {
    profit: "Recente (câștiguri zilnice)",
    cycle: "ciclul financiar",
    limit: "Limită unică",
    dividend_time: "Timpul de plată a dividendelor",
    every_day: "zilnic",
    funds: "Fonduri fiduciare",
    back: "Returnare la expirare",
    ransom: "Răscumpărare anticipată",
    label_amount: "Valoarea angajamentului",
    place_amount: "Vă rugăm să introduceți,,,",
    available: "disponibil",
    expect_income: "Venituri preconizate",
    title: "Minier blocat",
    tip: "Minerit pentru venituri",
    label_today_income: "Veniturile estimate astăzi",
    label_total_income: "Venituri cumulate",
    label_order: "Ordine în depozit",
    label_number: "Sumă unică",
    daily_return: "Returnarea zilnică",
    subscribe: "abonare",
    position: "Poziție",
    label_state: "Starea",
    subscribe_time: "subscription_time",
    expiration_time: "timp de expirare",
    income: "venituri",
    errorTip1: "Vă rugăm să introduceți suma",
    tip_tit: "Eşti sigur că vrei să ieşi mai devreme.",
  },
  footer: {
    nav1: "Acasă",
    nav2: "Comerț",
    nav3: "Poziție",
    nav4: "Hub",
    kline_set1: "Stabilirea pieței",
    kline_set2: "Configurări tranzacții",
  },
  login: {
    login: "Autentificare",
    register: "Înregistrare",
    type1: "Număr de telefon",
    type2: "Email",
    login_btn: "Autentifică-te",
    register_btn: "Înregistrează-te acum",
    psd_login: "Autentificare parolă",
    verify_login: "Autentificare cod de verificare",
    forget: "Am uitat parola?",
    no_account: "Fără cont?",
    have_account: "Cont existent",
  },
  home: {
    home: {
      nav1: "Clasamentul zilnic",
      nav2: "Baze",
      nav3: "Despre noi",
      nav4: "Sesizare",
      most_follow: "Popular",
      news_type1: "Straters",
      news_type2: "Știri",
      news_type3: "Calendar",
      news_tab1: "Toate",
      news_tab2: "Forex",
      news_tab3: "Acțiuni",
      news_tab4: "Mărfuri",
      news_tab5: "Indici",
      news_tab6: "Data",
      news_tab7: "Eveniment",
      news_tab8: "Vacanță",
      label_previous: "Anterior",
      label_predictive: "Predictiv",
      label_announced: "Anunțat ",
    },
    kilne: {
      label_bp: "Închide",
      label_sp: "Deschide",
      label_height: "Înălţime",
      label_low: "Minim ",
      tip: "Bună, sunt asistentul soiului StratTradeGPT",
      row1_tit: "Strategie",
      row1_rinei: "Intraday",
      row1_short: "Pe termen scurt",
      row1_middle: "Termen mediu",
      row1_time: "Timp de eliberare",
      row1_tit2: "Strategia de tranzacționare",
      data_sources: "Surse de date",
      row2_tit1: "Data",
      row2_tit2: "Informații",
      row2_label_sell: "Vânzător",
      row2_label_buy: "Cumpărător",
      tip2: "Doar pentru referință, nu ca poziție a companiei noastre",
      tip3: "Actualizare la fiecare 15 minute",
      row2_tit3: "Fluctuațiile prețurilor",
      minute: "Minut",
      today: "Astăzi",
      right_tit7: "Variația prețului",
      now_price: "Prețul curent",
      right_low_price: "Jos",
      right_height_price: "Înălţime",
      details: "Detalii",
      right_label1: "Numărul de tranzacții unice",
      right_label2: "Pârghie maximă",
      right_label3: "Dimensiunea contractului",
      right_label4: "Cantitatea totală maximă a poziției",
      right_label5: "Diferența variabilă de preț",
      right_label6: "Taxa de peste noapte",
      hand: "Mână",
      right_sell: "Vinde",
      right_buy: "Cumpără",
      right_collection_time: "Timpul colectării",
      right_tip3: "Colectează din întreaga dimensiune a tranzacției",
      right_label9: "Sesiune de tranzacționare",
      right_label10: "Perioada curentă",
      right_label11: "Perioada următoare",
    },
    placeorder: {
      direction_buyPrice: "Rata de cumpărare",
      direction_sellPrice: "Rata de vânzare",
      direction_sell: "Vinde",
      direction_buy: "Cumpără",
      market_price: "Ordinul de piață",
      check_price: "Înregistrare",
      sell_tip: "Vinde atunci când prețul îndeplinește condițiile",
      buy_tip: "Cumpărați atunci când prețul îndeplinește condițiile",
      trade_number: "Cantitatea tranzacției",
      hand: "Mână",
      select_lever: "Manetă de selecție",
      right_tip: "Depozit necesar",
      balance: "Disponibil",
      label_stop_win_price: "Opriți excedentul",
      label_stop_lose_price: "Stop loss",
      expected_profit_loss: "Profit și pierdere preconizate",
      trailing_stop: "Oprire de trasare",
    },
    ranking: {
      tit: "Clasamentul zilei în sus și în jos",
      p: "Capturați hotspot-uri și profitați de oportunități",
      renew: "Actualizat la",
      growth_chart: "Diagrama creșterii",
      drop_list: "Lista drop",
      show_closed_market: "Afișarea mărcilor de pe piață",
      filter1: "Întreg",
      filter2: "Străin ",
      filter3: "Mărfuri",
      filter4: "Certificat de acțiune",
      filter5: "Index",
      filter6: "Crypto",
      td1: "Clasament",
      td2: "Tendință",
      td3: "Creșterea zilnică/prețul de vânzare",
    },
    remind: {
      now_sell_price: "Prețul curent de vânzare",
      direction_buyPrice: "Rata de cumpărare",
      direction_sellPrice: "Rata de vânzare",
      direction_sell: "Vinde",
      direction_buy: "Cumpără",
      equal: "Egal",
      p: "Amintiți-mi când sunt atinse următoarele prețuri",
      btn1: "Setează memento-uri",
      list_tit: "Lista memento-urilor",
    },
  },
  my: {
    report: {
      tit: "Raportul contului",
      tab1: "raport",
      tab2: "Declarație zilnică",
      tab3: "extras lunar",
      history_tit: "Raportul istoricului tranzacțiilor",
      account_tit: "Raportul contului",
      filter: "ecran",
      tip1: "Vă rugăm să selectați intervalul de date corespunzător pentru a exporta raportul istoricului tranzacțiilor.",
      tip2: "Timp personalizat",
      email_send: "Trimite la e-mail",
    },
    accountMsg: {
      tit: "Informații despre cont",
      label1: "Informații personale",
    },
    bill: {
      tit: "Detalii privind fluxul de capital",
      all: "Întreg",
      time: "Timp",
    },
    feedback: {
      tit: "Recomandări funcționale",
    },
    identity: {
      tit: "Profil complet",
      type1: "ID național",
      type2: "Permis de conducere",
      type3: "Pașaport",
      tip: "Confirm că toate informațiile și documentele furnizate sunt complete, adevărate și exacte",
    },
    my: {
      is: "Stop",
      no: "Necertificat",
      verify: "Verificat",
      id_verify: "Verificarea identității",
      verify_tip: "Verificarea completă a identității pentru a depune și a începe tranzacționarea",
      no_verify: "Verifică acum",
      balance: "Disponibil",
      profit_loss: "Profit și pierdere",
      marginLevel: "Nivelul marjei",
      btn1: "Comută la un cont real",
      deposit_withdraw_record: "Înregistrările depozitelor și retragerilor",
      capital_flow_details: "Detalii privind fluxul de capital",
      welfare_center: "Centrul de asistență socială",
      tip1: "Ai avut",
      tip2: "Recompensa stiloului trebuie solicitată",
      feedback: "Recomandări funcționale",
      report: "Raportul contului",
      setting: "Configurări",
    },
    news: {
      tit: "Notificare",
    },
    record: {
      tit: "Înregistrările depozitelor și retragerilor",
      cycle: "Ciclu",
    },
    share: {
      tit: "Invită prietenii",
      invite_success: "Prieteni invitaţi cu succes",
      tip1: "Recompense în bani",
      tip2: "Invită prietenii să ia cel mai înalt",
      tip3: "Invită prietenii să colecteze bani",
    },
    welfare: {
      tit: "Centrul de asistență socială",
      tab1: "Rolă de cărți",
      tab2: "Activitate",
      history_record: "Istoric",
      period_validity: "Perioada de valabilitate",
    },
    wallet: {
      tit: "Portofel",
      addNew: "Adaugă un portofel nou",
    },
    withdraw: {
      tit: "Retragere",
      addNew: "Adaugă un portofel nou",
    },
  },
  order: {
    demo_account: "Cont simulat",
    real_account: "Cont live",
    tip: "După deschidere, aurul poate fi depus pentru tranzacții",
    tab1: "Poziție",
    tab2: "Înregistrare",
    tab3: "Istoric",
    list_empty: "Momentan nu există ordine în aşteptare",
    start_trade: "Începe tranzacționarea",
    hand_tip: "Intervalul de intrare",
    kline: {
      direction_sell: "Vinde",
      direction_buy: "Cumpără",
      open_price: "Preț deschis",
      now_price: "Prețul curent",
      trade_account: "Cont de tranzacționare",
      demo_account: "Cont simulat",
      order_id: "ID-ul comenzii",
      open_time: "Timp deschis",
      stop_profit: "Opriți excedentul",
      stop_loss: "Stop loss",
      trailing_stop: "Oprire de trasare",
      float_profit_loss: "P/L plutitoare",
      overnight_fee: "Taxa de peste noapte",
      margin: "Marjă",
      maintainsMargin: "Marjă de întreținere",
      open_value: "Valoarea de deschidere",
      now_value: "Valoarea curentă",
      notset: "Nu este setat",
      close_position: "Poziție de închidere",
      close_number: "Cantitate normală",
      onclick_close: "Poziția de închidere a unui singur clic",
      btn: "Confirmă poziţia de închidere",
      balance: "Disponibil",
      profit_loss: "Profit și pierdere",
      netValue: "Valoarea netă",
      marginLevel: "Nivelul marjei",
    },
    modify: {
      tit: "Modifică ordinea poziției",
      expected_profit_loss: "Profit și pierdere preconizate",
    },
  },
  setting: {
    security: {
      modify_password_tit: "Setează o parolă nouă",
      verify_email_tit: "Verifică e-mail",
      verify_password_tit: "Verifică parola",
      verify_password_tip: "Introduceți parola de autentificare StratTrade pentru a continua",
      verify_phone_tit: "Verifică numărul de telefon",
      tit: "Securitate",
      tip: "Pentru a proteja securitatea contului dvs. și pentru a accepta notificările noastre de servicii, este recomandat să activați cel puțin un tip de autentificare.",
      label1: "Schimbă parola",
      label2: "Dezabonare",
      label3: "Verificare dublă",
      label4: "Gestionarea echipamentelor",
      tip2: "Conectarea dispozitivului nou necesită verificare",
    },
    tradenews: {
      tit: "Ghidul tranzacțiilor",
      tab1: "Porneşte repede.",
      tab2: "Învățare avansată",
      tab3: "Ajutor",
      tab4: "Despre StratTrade",
      viewMore: "Vizualizează mai multe",

      details: {
        tit: "Introducere în schimbul valutar",
        tip1: "Durata estimată a învățării",
        tip2: "Minut",
      },
      tab1Row: {
        tit: "Aceasta este o sală de clasă pentru a învăța rapid să tranzacționeze cu StratTrade",
        tit1: "Cum se face tranzacționarea pe StratTrade?",
        tit2: "Cum să deschizi un cont real？",
        tit3: "Cum să deschizi o poziție pentru tranzacționare？",
        tit4: "Cum să setați stop profit și stop loss？",
        tit5: "Cum se închide o poziție？",
        p1: "Deschiderea unei poziții în StratTrade înseamnă că sunteți interesat de acest subiect.",
        p2: "Tranzacționare bazată pe fluctuațiile prețurilor produsului țintă",
        p3: "De exemplu, dacă vă așteptați ca prețul aurului să crească, cumpărați produsul; Dimpotrivă, dacă preziceți că prețul aurului va scădea, atunci vindeți produsul. Dacă viitorul îndeplinește așteptările, puteți",
        p4: "Profitând de ea",
        p5: "În plus, StratTrade oferă 'tranzacționare cu efect de levier'. Puteți utiliza efectul de levier pentru a tranzacționa mai multe ținte cu aceeași sumă de fonduri.",
        p6: "Efectuarea de levier poate amplifica profiturile și, de asemenea, amplifica pierderile",
        p7: "Aceasta este cunoscută și sub numele de 'tranzacționare în marjă'. Când deschideți o poziție, contul dvs. StratTrade va deduce un anumit sold ca",
        p8: "MARGINA INIȚIALĂ",
        p9: "Selectați produsele și direcțiile de cumpărare și vânzare",
        p10: "Tranzacție",
        p11: "Faceţi clic pe 'Cumpăraţi/vindeţi' pentru a finaliza plasarea comenzii",
        p12: "Setarea 'stop profit' sau 'stop loss' vă poate ajuta",
        p13: "Blocarea în timp util a profiturilor și limitarea pierderilor",
        p14: "Puteți face clic pe pagina de deschidere cu succes",
        p15: "Stop profit/stop loss",
        p16: "Configurați-l sau puteți face clic pe butonul 'Modificați' pentru comanda neechilibrată pentru a opri profitul și pierderea",
        p17: "Setează sau anulează",
        p18: "Puteți vizualiza profiturile și pierderile în 'poziții închise'",
      },
      tab3Row: {
        h1: "Bun venit la StratTrade",
        h2: "Ajutor",
        tit0: "Toate problemele",
        tit1: "Cont simulat",
        tit2: "Deschiderea unui cont",
        tit3: "Cash in și out",
        tit4: "Setări platformă",
        tit5: "Operațiuni tranzacționale",
        tit6: "Taxe și taxe",
        tit7: "Securitate financiară și reglementări",
        tit1Tit1: "StratTrade oferă conturi simulate",
        tit1Cont1: "<p>StratTrade oferă conturi simulate pentru a opera clienții, permițându-vă să utilizați platforma noastră online și aplicațiile mobile pentru tranzacții și să vă familiarizați cu operațiunile platformei fără riscuri</p>",
        tit1Tit2: "Cum se creează un cont simulat?",
        tit1Cont2: "<p>Trebuie doar să faceți clic aici pentru a vă completa adresa de e-mail sau numărul de telefon și să setați parola personală pentru a finaliza înregistrarea și pentru a obține un cont simulat </p>",
        tit1Tit3: "Care este utilizarea conturilor simulate?",
        tit1Cont3: "<p>Conturile simulate sunt în mare parte identice cu conturile reale în ceea ce privește interfața tranzacțiilor, datele și operațiunile. Există un fond virtual de 50000 USD în contul simulat, cu scopul de a familiariza clienții cu diferitele funcții ale platformei prin operațiuni simulate fără niciun risc financiar</p>",
        tit1Tit4: "Cum se injectează fonduri într-un cont simulat?",
        tit1Cont4: "<p>Nu este necesar să injectați fonduri în contul de simulare. Odată ce ați înregistrat cu succes contul de simulare, va exista un total de 50000 USD sau AUD în cont</p><p>Când valoarea netă din contul simulat este mai mică de 200 USD sau AUD, sistemul vă va umple automat fondurile contului la 50000 USD sau AUD</p>",
        tit1Tit5: "Contul simulat are o perioadă de utilizare",
        tit1Cont5: "<p>Perioada de valabilitate a contului simulat este de 90 de zile. Dacă nu deschideți un cont real după 90 de zile, contul simulat va fi înghețat și nu poate fi tranzacționat și va fi dezghețat atunci când deschideți un cont real.</ p><p>Dacă deschideți un cont real în termen de 90 de zile, contul simulat va fi valabil pentru o perioadă lungă de timp. Chiar și după deschiderea unui cont real, puteți continua să operați pe un cont simulat.</p>",
        tit2Tit1: "Câte tipuri de conturi oferă StratTrade?",
        tit2Cont1: "<p>Oferim numai conturi standard și profesionale de tranzacționare pentru persoane fizice și nu sprijinim deschiderea conturilor companiei sau comune</p>",
        tit2Tit2: "În ce valută pot deschide un cont de tranzacționare?",
        tit2Cont3: "<p>Când deschideți un cont, sistemul va determina automat moneda de decontare în funcție de regiunea dvs. și nu va fi modificabilă</p>",
        tit2Tit4: "Cum să deschizi un cont real?",
        tit2Cont4: "<p>Urmați pașii de mai jos pentru a deschide un cont real</p><p>1. Introduceți pagina de înregistrare StratTrade și urmați instrucțiunile pentru a înregistra un număr de telefon de e-mail, setați o parolă de autentificare și creați-o automat după trimiterea confirmării</p><p></p><p></p>",
        tit3Tit1: "Cum pot face o depunere?",
        tit3Cont1: "<p>StratTrade StratTrade oferă diferite metode de depunere pentru clienți, inclusiv, dar nu pot fi limitate la următoarele:</p><p>1.Visa/Mastercard - De obicei primit imediat</p><p>2.Plata bancară online se face în general în termen de o zi lucrătoare în avans</p><p>3.Portofele electronice (cum ar fi Skrill, Momo, Zalo. Touch'n GO, Boost, etc.) - de obicei chitanță instantanee</p><p>4. Cardul ATM - de obicei chitanță instantanee</p><p>5.QR mobil online banking - de obicei chitanță instantanee</p>",
        tit3Tit2: "Pot folosi un cont bancar/card sub numele unui terț pentru a depune sau retrage fonduri",
        tit3Cont2: "<p>StratTrade nu acceptă depozite terțe părți. Vă rugăm să utilizați contul bancar personal / cardul pe nume propriu pentru a depune sau retrage fonduri. Dacă folosiți numele altcuiva sau contul bancar / cardul companiei pentru a depune bani, fondurile vor fi returnate</p>",
        tit3Tit3: "Cum să solicitați retragerea din contul StratTrade?",
        tit3Cont3: "<p>După ce vă conectați la platforma site-ului web, puteți face clic pe 'Cash Out', selectați un cont bancar sau Visa/Mastercard, completați suma cash out, apoi apăsați 'Trimite'. După confirmarea corectă a informațiilor, StratTrade va procesa cererea dumneavoastră în termen de 1-2 zile lucrătoare</p>",
        tit4Tit1: "De câte ori StratTrade oferă tranzacționare cu efect de levier?",
        tit4Cont1: "<p>Rata de levier furnizată de platforma StratTrade este stabilită în conformitate cu reglementările autorităților de reglementare, iar levierul maxim pentru diferite produse variază, de asemenea. Vă rugăm să vă conectați la platforma de tranzacționare pentru detalii.</p>",
        tit4Tit2: "Cum să-mi schimb parola?",
        tit4Cont2: "<p>După ce vă conectați la platformă, puteți alege [Mai multe] - [Setări de securitate] - T Schimbați parola] pentru a reseta noua parolă</p>",
        tit4Tit3: "Cum să vă conectați la platforma de tranzacționare StratTrade?",
        tit4Cont3: "<p>Aplicație: Mergeți la 'My' și faceți clic pe 'Login Registration' în colțul din stânga sus pentru a intra în pagina de conectare.</ p>Web: Faceți clic pe 'Login' în colțul din dreapta sus al site-ului oficial StratTrade pentru a intra în pagina de conectare.<p></p><p></p><p></p><p></p>",
        tit4Tit4: "Care sunt metodele pentru memento-urile platformei?",
        tit4Cont4: "<p>Alerte StratTrade prin e-mail, SMS și platformă push.</p>",
        tit5Tit1: "Ce diferență de preț oferă StratTrade?",
        tit5Cont1: "<p>Noi percepem o diferență mică de preț și nu percepem nici un comision. Diferența de preț este plutitoare, iar diferența reală de preț percepută va depinde de varietatea pe care o tranzacționați, iar informațiile specifice vor fi afișate pe platforma de tranzacționare în timp real.</p>",
        tit5Tit2: "Cum se stabilește o listă de prețuri de piață?",
        tit5Cont2: "<p>Dacă dați clic pe 'Tranzacționare' veți afișa toate produsele pe care platforma le poate oferi pentru tranzacționare. Puteți introduce codul soiului sau numele în caseta de introducere din dreapta pentru a căuta, selecta un produs și faceți clic pe \"Cumpără/Vinde\" SEL/BUY \"pentru a pop-up o fereastră de tranzacționare. În fereastra de tranzacționare, puteți vedea prețul curent și marja necesară preconizată. Puteți ajusta manual numărul de poziții deschise și configura stop profit și stop loss pentru controlul riscurilor</p>",
        tit5Tit3: "Cum se stabilește un formular de înregistrare?",
        tit5Cont3: "<p>Selectând un produs în [Tranzacționare] și făcând clic pe [Vinde/Cumpără] va apărea o fereastră de tranzacționare</p>",
        tit5Tit4: "Cum se închide o poziție?",
        tit5Cont4: "<p>Pentru a închide o poziție, faceți clic pe ''Deschide'' pe platformă în ''Poziție'', apoi selectați poziția pe care doriți să o închideți, apoi faceți clic pe butonul ''Închide'' din partea dreaptă</p>",
        tit5Tit5: "Cum se modifică sau șterge o comandă în așteptare?",
        tit5Cont5: "<p>Pentru a modifica sau șterge ordinele, faceți clic pe ''Ordine'' pe platforma de vizualizare din secțiunea ''Poziție'', apoi selectați ordinele pe care doriți să le modificați sau ștergeți în curs de desfășurare</p>",
        tit6Tit1: "Va fi percepută o taxă de manipulare pentru utilizarea platformei de tranzacționare?",
        tit6Cont1: "<p>StratTrade este o platformă de tranzacționare complet fără comisioane. Vom percepe o diferență de preț scăzută și, în funcție de situația dvs. specifică a tranzacției, platforma poate suporta taxe suplimentare, cum ar fi dobânda overnight</p>",
        tit6Tit2: "Există vreo taxă pentru depozit?",
        tit6Cont2: "<p>Nu vom percepe nici o taxă legată de depozit clienților, dar plata sau banca intermediară poate percepe taxe de gestionare. Vă sugerăm să întrebați cu băncile relevante dacă v-au perceput taxe.</p>",
        tit6Tit3: "Există vreo taxă pentru retragere?",
        tit6Cont3: "<p>StratTrade nu va percepe clienților nici o taxă legată de retragerea fondurilor, cu excepția cazului în care suma pe care o retrageți este sub cerința minimă sau depășește numărul maxim de retrageri gratuite pentru luna respectivă. Pentru informații mai detaliate, consultați politica noastră de retragere.</p>",
        tit7Tit1: "Fondurile pe care le depun în contul meu StratTrade vor fi folosite în alte scopuri?",
        tit7Cont1: "<p>Depozitele clienților retail sunt depozitate separat în conturi fiduciare atunci când este necesar, în conformitate cu reglementările de reglementare. Atunci când depuneți fonduri în conturile clienților sau retrageți fonduri din conturile clienților sau efectuați plăți, StratTrade respectă strict reglementările de reglementare privind plățile clienților.</p>",
        tit7Tit2: "Dacă StratTrade dă faliment sau are datorii, fondurile mele vor fi afectate.?",
        tit7Cont2: "<p>Depozitele clienților retail sunt depozitate separat în conturi fiduciare atunci când este necesar, în conformitate cu reglementările de reglementare. Dacă StratTrade dă faliment, își revocă licența sau nu poate continua activitatea, fondurile clienților pot fi în continuare puse în carantină și supuse supravegherii reglementărilor privind fondurile clienților în legile de reglementare</p>",
        tit7Tit3: "StratTrade respectă reglementările legale?",
        tit7Cont3: "<p>Compania noastră respectă pe deplin legile companiilor relevante și legile și reglementările financiare de reglementare și este un furnizor de servicii financiare autorizat și strict reglementat de agențiile de reglementare relevante. Compania noastră oferă o protecție cuprinzătoare și suficientă pentru securitatea financiară a clienților în conformitate cu cerințele stricte de reglementare.</p>",
        tit7Tit4: "Informațiile personale ale clientului sunt sigure?",
        tit7Cont4: "<p>StratTrade respectă strict prevederile Regulamentului privind protecția datelor cu caracter personal, iar datele cu caracter personal ale clienților nu vor fi divulgate niciodată. Compania se va asigura că angajații respectă standardele stricte de securitate și confidențialitate prevăzute de legi și reglementări și acordă o atenție deosebită modalităților de confidențialitate și utilizare a datelor clienților. Pentru detalii, vă rugăm să consultați documentele de dezvăluire legală ale companiei noastre.</p>",
      },
      tab4Row: {
        span: "Metoda de verificare Q",
        p1: "StratTrade este răspunsul pentru cei care caută platforme derivate de criptovaluta de nivel profesional. Scopul nostru este de a crea o piață eficientă și echitabilă care să conecteze comercianții din diferite medii și stiluri de tranzacționare. Capacitatea ridicată a motorului de potrivire, latența scăzută, managementul avansat al riscului și lichiditatea ridicată fac din StratTrade un participant unic pe piață.",
        p2: "Scopul inițial al StratTrade a fost de a crea primul schimb de opțiuni de criptomonedă din lume. În ciuda faptului că este o sarcină extrem de dificilă, echipa a finalizat lucrările de dezvoltare a sistemului în doi ani. În iunie 2016, StratTrade a fost lansat oficial.",
        p3: "StratTrade este un schimb de tranzacții futures și opțiuni cu sediul în Panama City, Panama. StratTrade a fost inițial o platformă complet dedicată de tranzacționare BTC, cu toate acestea, de atunci, au fost adăugate contracte ETH și vor sosi și mai multe contracte valutare.",
        p4: "În prezent, clienții noștri pot tranzacționa contracte perpetue, contracte futures și contracte de opțiuni. StratTrade s-a dezvoltat rapid și este una dintre cele mai bune burse care oferă futures-uri de criptovaluta și tranzacționare cu contracte perpetue. În plus, StratTrade rămâne un schimb de vârf care oferă opțiuni europene de decontare a banilor și continuă să stabilească standarde pentru industrie.",
        p5: "StratTrade Holding este autorizată și reglementată de Autoritatea Monetară a Insulelor Cayman (CIMA) cu un număr de licență SIB 1612446. Pentru mai multe detalii despre plăcuța de înmatriculare Cayman, vă rugăm să vizitați site-ul oficial al CIMA la www.cima.ky(",
        p6: ") Condu o interogare. StratTradeGlobal deține o licență de servicii financiare (AFSL 398528) autorizată de Comisia Australiană pentru valori mobiliare și investiții (ASIC). Pentru mai multe detalii despre plăcuţa de înmatriculare australiană, vă rugăm să vizitaţi site-ul oficial ASIC la www.asic.gov.au (",
        p7: ") Condu o interogare. StratTrade International este autorizată și reglementată de Comisia Serviciilor Financiare din Mauritius (FSC) cu un număr de licență GB20025791. Pentru mai multe informații despre plăcuța de înmatriculare Mauritius, vă rugăm să vizitați site-ul oficial FSC la www.fscmauricius.org(",
        p8: ")Condu o interogare. Toate operațiunile de afaceri ale StratTrade se desfășoară sub supraveghere strictă și respectă toate reglementările",
        p9: "Context",
        p10: "StratTrade este o echipă senior cu experiență bogată și cunoștințe în domeniul tranzacționării financiare și industriilor fintech, fondată în Melbourne, Australia",
        p11: "Filozofia StratTrade este de a face tranzacțiile mai ușoare și mai prietenoase. Strategia noastră holistică și modelul nostru de afaceri ne permit să avem o înțelegere cuprinzătoare și aprofundată a dezvoltării industriei și a cererii pieței, permițându-ne să alocăm resurse mai direcționate, să inovăm continuu tehnologii și să optimizăm eficiența, oferind clienților experiențe de tranzacționare mai convenabile și prietenoase",
        p12: "De ce să alegi",
        p13: "Condiții de tranzacționare prag scăzut",
        p14: "Platformă de tranzacționare simplă și intuitivă",
        p15: "Costuri competitive ale tranzacțiilor",
        p16: "Supravegheat de autoritățile din industrie",
        p17: "Sprijin online la nivel înalt",
        p18: "Protecția echilibrului negativ",
        p19: "Premii și onoruri",
        p20: "StratTrade este onorată să primească mai multe premii, inclusiv cel mai bun broker multiactiv al World Finance Awards, cea mai bună platformă de tranzacționare mobilă a revistei Global BrandsMagazine din regiunea Asia Pacific și Broker Global cu tehnologie financiară în creștere rapidă, toate acestea confirmă eforturile și dedicarea continuă a echipei.",
        p21: "Cel mai bun broker de active multiple",
        p22: "Filozofia noastră operaţională",
        p23: "Valorile noastre",
        p24: "Suntem una dintre platformele de tranzacționare cu criptomonedă cu cea mai rapidă creștere din lume și credem că valorile noastre de bază sunt forța motrice din spatele acestui succes.",
        p25: "Sinceritate şi integritate",
        p26: "Încrederea utilizatorilor este esențială; Prin urmare, ne desfășurăm afacerea cu cele mai înalte standarde de integritate, oferind un sistem echitabil de management al riscurilor, tratând toți participanții la piață în mod egal și soluții avansate de securitate.",
        p27: "Calitatea serviciilor",
        p28: "Tranzacțiile de criptomonedă sunt un proces fără frontiere, indiferent de vreme, în care calitatea serviciilor nu este compromisă. Clienții sunt valoarea noastră cea mai importantă; Prin urmare, obiectivul nostru este de a oferi o experiență de tranzacționare neîntreruptă și lină. Scopul nostru este de a realiza acest lucru prin extinderea și îmbunătățirea continuă a arhitecturii sistemului nostru, minimizarea timpului de întreținere și furnizarea de servicii pentru clienți în timp util și multilingv.",
        p29: "creează",
        p30: "În mediul rapid al criptomonedelor, inovația este necesară. StratTrade este o companie bazată pe soluții menită să ofere cea mai bună experiență de tranzacționare pentru milioane de utilizatori, înrădăcinată în arhitectura noastră de sistem scalabilă și de înaltă performanță.",
        p31: "De ce alegeți instrumentele derivate？",
        p32: "Tranzacționarea instrumentelor derivate are multe beneficii - costuri de tranzacție mai mici, efect de levier mai mare, lichiditate mai mare și vânzare în lipsă mai ușoară.",
        p33: "Tranzacționarea cu instrumente derivate permite, de asemenea, strategii de tranzacționare mai diverse. Traderii pot folosi instrumente derivate pentru a acoperi riscul, arbitrajul sau tranzacționarea speculativă.",
        p34: "știință și tehnologie",
        p35: "Pentru a asigura un sistem de înaltă performanță, acesta a fost dezvoltat timp de peste doi ani înainte de a intra în funcțiune.",
        p36: "Cadrul dezvoltat pentru această platformă este de a asigura capacitatea de a procesa un număr mare de solicitări cu latență scăzută. Am dezvoltat un motor unic de potrivire special pentru StratTrade, iar toate tehnologiile noastre sunt proprietare.",
        p37: "De la lansarea sa, sistemul nostru incremental de compensare și management al riscurilor a asigurat zero pierderi socializate.",
        p38: "La StratTrade, credem în viitorul criptomonedelor. Scopul nostru este să continuăm să fim în fruntea pieței derivatelor criptovaluta. Ne imaginăm că în viitor, criptomonedele vor fi folosite de toată lumea și tranzacționate de milioane de oameni. Suntem pregătiţi pentru viitor, la fel şi sistemul nostru.",
      },
    },
    language: {
      tit: "Alege limba",
    },
    market: {
      tit: "Stabilirea pieței",
      tit1: "Afișarea prețului",
      tit2: "Culoarea suișurilor și coborârilor",
      buy_price: "Rata de cumpărare",
      buy_price_tip: "Desenați o linie K la prețul de cumpărare",
      sell_price: "Rata de vânzare",
      sell_price_tip: "Trageți linia K la prețul de vânzare",
      color1: "Creştere verde şi cădere roşie",
      color2: "Creştere roşie şi cădere verde",
    },
    notice: {
      tit: "Setări de notificări",
      tit1: "Deschide canale de notificare",
      p1: "Vă rugăm să deschideți/verificați următoarele canale de notificare, mesajele importante nu sunt pierdute!",
      message: "Mesaj text",
      push: "Împinge",
      verify: "Testare și verificare",
      verified: "Verificat",
      email: "E-mail",
      type1: "COMERCIALIZAREA",
      tit2: "Notificarea evenimentelor de marketing",
      desc2: "Informații promoționale, activități operaționale și alte mesaje ale platformei",
      tit3: "Instrucţiuni de utilizare",
      desc3: "Materiale bogate și profesionale de cunoștințe",
      tit4: "Memento de informații",
      desc4: "Date financiare recente și informații de știri",
      tit5: "Memento inteligent al fluctuației",
      desc5: "Inclusiv fluctuațiile de preț ale soiurilor importante, ajutându-vă să profitați rapid de oportunități",
      type2: "Clasa raportului",
      tit6: "Raportul contului",
      desc6: "Raportul contului dumneavoastră va fi trimis lunar în următorul mod",
      cycle: "Ciclu",
      month: "Lunar",
    },
    protocol: {
      tit: "Declarație și acord",
      label1: "Politica de confidențialitate",
      label2: "Declarația de informare a produsului",
      label3: "Contract cu clienții",
      label4: "Declarația de informare a riscurilor",
    },
    setting: {
      tit: "Configurări",
      label1: "Setare de securitate",
      label2: "Stabilirea pieței",
      label3: "Configurări tranzacții",
      label4: "Limba",
      label5: "Modul temă",
      label6: "setări de notificări",
      label7: "Ghidul tranzacțiilor",
      label8: "Declarație și acord",
      label9: "Curăță cache",
      label10: "Versiune",
      logout: "Deconectați-vă de la conectare",
    },
    theme: {
      tit: "Modul temă",
      theme1: "Lumină",
      theme2: "Întunecat",
    },
    trade: {
      tit: "Configurări tranzacții",
      onclick_close: "Poziția de închidere a unui singur clic",
    }
  },
  trade: {
    fliter_type1: "Opțional",
    fliter_type2: "Piață",
    place_search: "Introduceți numele soiului/codul de căutare",
    fliter_type3: "Popular",
    fliter_type4: "Străin ",
    fliter_type5: "Mărfuri",
    fliter_type6: "Partajare ",
    fliter_type7: "Index",
    fliter_type8: "Crypto",
    tip1: "Verificarea completă a identității pentru a depune și a începe tranzacționarea",
    sell: "Vinde",
    buy: "Cumpără",
    add_optional: "Adaugă selecție automată",
    optional_recommend: "Recomandarea de auto-selecție",
    change_batch: "Modificarea lotului",
    edit_tit: "Editează selecția",
    breed: "Rasă",
    topping: "Încărcare",
    sort: "Sortare",
    all: "Selectează tot",
  },
  table: {
    label_breed: "Rasă",
    label_direction: "Direcție",
    label_price: "Preț",
    label_operate: "Operați",
    empty_tip1: "Niciun memento momentan",
    empty_tip2: "Fără înregistrări de depozit",
    empty_tip3: "Nicio înregistrare a plăților în numerar",
    label_amount: "Suma",
    label_type: "Tipuri ",
    label_time: "Timp",
    label_name: "Denumire",
    label_email: "Email",
    label_address: "Țară",
    label_account: "Numărul contului de tranzacționare",
    label_currency: "Moneda de bază",
    label_company: "Entitate de serviciu curentă",
  },
  form: {
    to: "Pentru",
    start_time: "Ora de începere",
    end_time: "Ora sfârșitului",
    week: "Săptămâna trecută",
    month: "Luna trecută",
    threeMonth: "Ultimele trei luni",
    sixMonth: "Ultimele şase luni",
    label_feedback: "Probleme și sugestii",
    place_feedback: "Vă rugăm să introduceți întrebarea sau sugestia dumneavoastră",
    label_img: "Imagine",
    label_img_tip: "Opțional, furnizează capturi de ecran ale problemei",
    feedback_tip: "Dacă aveți întrebări urgente, vă rugăm să contactați",
    online: "Serviciul online",
    label_cardNumber: "Număr ID",
    label_realName: "Prenumele",
    place_realName: "Vă rugăm să introduceți prenumele",
    message_realName: "Vă rugăm să introduceți prenumele",
    label_firstName: "Nume de familie",
    place_firstName: "Vă rugăm să introduceți numele de familie",
    message_firstName: "Vă rugăm să introduceți numele de familie",
    label_lastName: "Nume mijlociu",
    place_lastName: "Opțional",
    message_lastName: "Introduceți un nume",
    label_birthday: "Data nașterii",
    place_birthday: "DD/MM/YYYY",
    message_birthday: "Vă rugăm să introduceți data nașterii",
    label_idType: "Tipul verificării",
    tip_cardMain: "Faceți sau încărcați o fotografie a frontului cărții de identitate",
    tip_cardBack: "Faceți sau încărcați o fotografie a celui din spatele cărții de identitate",
    tip_cardMain1: "Faceți sau încărcați o fotografie a faței permisului de conducere",
    tip_cardBack1: "Faceți sau încărcați o fotografie cu spatele permisului de conducere",
    tip_cardMain2: "Luați sau Încărcați o fotografie a pașaportului dvs.",
    tip_cardBack2: "Luați sau Încărcați o fotografie a pașaportului dvs.",
    label_password: "Parolă",
    place_password: "Vă rugăm să introduceți parola",
    message_password: "Vă rugăm să introduceți parola",
    label_crmpsd: "Confirmă parola",
    place_crmpsd: "Vă rugăm să introduceți parola din nou",
    message_crmpsd: "Vă rugăm să introduceți parola din nou",
    label_email: "E-mail",
    place_email: "Adresă de e-mail",
    message_email: "Vă rugăm să introduceți adresa de e-mail",
    label_captcha: " Codul de verificare",
    place_captcha: "Vă rugăm să introduceți codul de verificare",
    message_captcha: "Vă rugăm să introduceți codul de verificare",
    get_captcha: "Codul de verificare",
    label_phone: "Telefon",
    place_phone: "Introduceți un număr de telefon",
    message_phone: "Introduceți un număr de telefon",
    labelAmount: "Suma",
    placeAmount: "Vă rugăm să introduceți suma",
    messageAmount: "Vă rugăm să introduceți suma",
    labelWalletName: "Numele portofelului",
    messageWalletName: "Introduceți numele portofelului",
    placeWalletName: "Introduceți numele portofelului",
    labelWalletAddress: "Adresa portofelului",
    messageWalletAddress: "Introduceți adresa portofelului",
    placeWalletAddress: "Introduceți adresa portofelului",
    labelWithdrawAccount: "Cont de retragere",
    placeWithdrawAccount: "Cont de retragere",
    messageWithdrawAccount: "Cont de retragere",
    placeTimeLimit: "Vă rugăm să introduceți timeLimit",
    messageTimeLimit: "Vă rugăm să introduceți timeLimit",
  },
  city: {
    albania: "Albania",
    algeria: "Algeria",
    argentina: "Argentina",
    armenia: "Armenia",
    australia: "Australia",
    pakistan: "Pakistan",
    austria: "Austria",
    bahrain: "Bahrain",
    belgium: "Belgia",
    bosnia_and_Herzegovina: "Bosnia şi Herţegovina",
    brazil: "Brazilia",
    brunei: "Brunei",
    bulgaria: "Bulgaria",
    cambodia: "Cambodgia",
    canada: "Canada",
    cameroon: "Camerun",
    chile: "Chile",
    colombia: "Columbia",
    costa_Rica: "Costa Rica",
    croatia: "Croația",
    cyprus: "Cipru",
    czech_Republic: "Republica Cehă",
    denmark: "Danemarca",
    dominican_Republic: "Republica Dominicană",
    egypt: "Egipt",
    estonia: "Estonia",
    ethiopia: "Etiopia",
    finland: "Finlanda",
    france: "Franţa",
    georgia: "Georgia",
    germany: "Germania",
    ghana: "Ghana",
    greece: "Grecia",
    guyana: "Guyana",
    honduras: "Honduras",
    hong_Kong_China: "Hong Kong, China",
    hungary: "Ungaria",
    iceland: "Islanda",
    ireland: "Irlanda",
    italy: "Italia",
    india: "India",
    indonesia: "Indonezia",
    israel: "Israel",
    iran: "Iran",
    iraq: "Irak",
    japan: "Japonia",
    kazakstan: "Kazahstan",
    kenya: "Kenya",
    korea: "Coreea",
    kuwait: "Kuwait",
    kyrgyzstan: "Kârgâzstan",
    laos: "Laos",
    latvia: "Letonia",
    lithuania: "Lithuania",
    luxembourg: "Luxembourg",
    macao_China: "Macao_China",
    macedonia: "Macedonia",
    malaysia: "Malaezia",
    malta: "Malta",
    mexico: "Mexic",
    moldova: "Moldova",
    monaco: "Monaco",
    mongolia: "Mongolia",
    montenegro: "Muntenegru",
    morocco: "Maroc",
    myanmar: "Myanmar",
    netherlands: "Olanda",
    new_Zealand: " Noua_Zeelandă",
    nepal: "Nepal",
    nigeria: "Nigeria",
    norway: "Norvegiană",
    oman: "Oman",
    palestine: "Palestina",
    panama: "Panama",
    paraguay: "Paraguay",
    peru: "Peru",
    philippines: "Filipine",
    poland: "Poland",
    portugal: "Portugalia",
    puerto_Rico: "Puerto Rico",
    qatar: "Qatar",
    romania: "România",
    russia: "Rusia",
    republic_of_Trinidad_and_Tobago: "Republica Trinidad și Tobago",
    rwanda: "Rwanda",
    saudi_Arabia: "Arabia Saudită",
    serbia: "Serbia",
    singapore: "Singapore",
    slovakia: "Slovacia",
    slovenia: "Slovenia",
    south_Africa: "Africa de Sud",
    spain: "Spania",
    sri_Lanka: "Sri Lanka",
    sweden: "Suedia",
    switzerland: "Elveţia",
    taiwan_China: "Taiwan_China",
    tajikistan: "Tadjikistanul",
    tanzania: "Tanzania",
    thailand: "Thailanda",
    turkey: "Turcia",
    turkmenistan: "Turkmenistan",
    ukraine: "Ucraina",
    united_Arab_Emirates: "Emiratele Arabe Unite",
    united_Kingdom: "Regatul Unit",
    united_States: " Statele Unite",
    uzbekistan: "Uzbekistan",
    venezuela: "Venezuela",
    vietnam: "Vietnam",
    afghanistan: "Afghanistan",
    angola: "Angola",
    azerbaijan: "Azerbaidjan",
    bangladesh: "Bangladesh",
    belarus: "Belarus",
    belize: "Belize",
    benin: "Benin",
    bhutan: "Bhutan",
    bolivia: "Bolivia",
    botswana: "Botswana",
    british_Virgin_Islands: "Insulele Virgine Britanice",
    burkina_Faso: "Burkina Faso",
    burundi: "Burundi",
    cape_Verde: "Capul Verde",
    cayman_Islands: "Cayman Islands",
    central_African_Republic: "Republica Centrafricană",
    chad: "Chad",
    comoros: "Comoros",
    the_Republic_of_the_Congo: " Republica_Congo",
    democratic_Republic_of_the_Congo: "Republica Democratică Congo",
    djibouti: "Djibouti",
    ecuador: "Ecuador",
    el_Salvador: "El Salvador",
    equatorial_Guinea: "Guineea Ecuatorială",
    eritrea: "Eritreea",
    fiji: "Fiji",
    gabon: "Gabon",
    gambia: "Gambia",
    greenland: "Groenlanda",
    guatemala: "Guatemala",
    guinea: "Guineea",
    haiti: "Haiti",
    isle_of_Man: " Isle_of_Man",
    cote_d_Ivoire: "Cote_d_Ivoir",
    jamaica: "Jamaica",
    jordan: "Iordania",
    lebanon: "Liban",
    lesotho: "Lesotho",
    liberia: "Liberia",
    libya: "Libia",
    madagascar: "Madagascar",
    malawi: "Malawi",
    maldives: "Maldive",
    mali: "Mali",
    mauritania: "Mauritania",
    mauritius: "Mauritius",
    mozambique: "Mozambic",
    namibia: "Namibia",
    nicaragua: "Nicaragua",
    republic_of_Niger: "Republica Niger",
    north_Korea: " Coreea de Nord",
    reunion: "Reunion",
    san_Marino: "SAN Marino",
    senegal: "Senegal",
    sierra_Leone: "Sierra Leone",
    somalia: "Somalia",
    sudan: "Sudan",
    suriname: "Suriname",
    eswatini: "Eswatini",
    syria: "Siria",
    togo: "Togo",
    tonga: "Tonga",
    tunisia: "Tunisia",
    united_States_Virgin_Islands: "Insulele Virgine ale Statelor Unite",
    uganda: "Uganda",
    uruguay: "Uruguay",
    vatican: "Vatican",
    yemen: "Yemen",
    yugoslavia: "Iugoslavia",
    zambia: "Zambia",
    zimbabwe: "Zimbabwe",
    china: "China",
  }
};
