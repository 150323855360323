export default {
    common: {
        expected: "Entrate previste",
        view: "Visualizza",
        confirm: "Conferma",
        cancel: "Annulla",
        deposit: "Deposito",
        withdraw: "Ritiro",
        noData: "Attualmente non ci sono dati disponibili",
        submit: "Invia",
        modify: "Modifica",
        hand: "Mano",
        confirm_verify: "Conferma convalida",
        next: "Passo successivo",
        complete: "Fatto",
        delete: "Elimina",
        countryCode: "Codice paese",
        no_balance: "Saldo insufficiente del conto",
    },
    currency: {
        btcusdt: "Bitcoin",
        ethusdt: "Eteri",
        ltcusdt: "Litecoin",
        xrpusdt: "Ripple (coin)",
        adausdt: "Ada valuta",
        eosusdt: "Moneta Pomelo",
        dotusdt: "Boca coin",
        trxusdt: "Wave field coin",
        xmrusdt: "Monroe Coin",
        dogeusdt: "Dogecoin",
        bnbusdt: "BNB",
        solusdt: "SOL",
        aptusdt: "Aptusdt",
        fx_saudusd: "AUD USD",
        fx_sgbpusd: "GBP USD",
        fx_seurusd: "Eurodollar",
        fx_snzdusd: "New Zealand Dollar (NZD) USD",
        fx_susdcad: "USD CAD",
        fx_susdchf: "USD CHF",
        fx_susdjpy: "USD JPY",
        fx_saudcad: "AUD CAD",
        fx_saudchf: "AUD CHF",
        fx_saudjpy: "AUD JPY",
        fx_saudnzd: "AUD New Zealand Dollar (NZD)",
        fx_scadchf: "CAD Swiss Franc",
        fx_scadjpy: "Canadian yen",
        fx_schfjpy: "CHF JPY",
        fx_seuraud: "EUR AUD",
        fx_seurcad: "EUR CAD",
        fx_seurgbp: "Euro Pound",
        fx_seurjpy: "Euro Yen",
        fx_seurnzd: "Euro New Zealand dollar (NZD)",
        fx_sgbpaud: "GBP AUD",
        fx_sgbpchf: "GBP CHF",
        fx_sgbpjpy: "GBP JPY",
        fx_sgbpnzd: "GBP NZD",
        fx_scadnzd: "Dollari canadesi e neozelandesi",
        hf_CL: "WTI New York Crude Oil",
        hf_OIL: "Petrolio greggio Brent",
        hf_GC: "Oro di New York",
        hf_SI: "Argento di New York",
        hf_HG: "Copper (US)",
        hf_NG: "U.S. Natural Gas",
        hf_CAD: "Copper (chemistry)",
        hf_AHD: "Alluminio Londra",
        HX_AAPL: "Pomegranate",
        HX_BABA: "Alibaba, società di commercio elettronico della RPC",
        HX_GOOG: "Google",
        HX_WMT: "Wal-Mart, Walmart (retailer)",
        HX_AMZN: "HX_AMZN",
        HX_TSLA: "Tesla",
        HX_N225: "Nikkei 225",
        HX_GDAXI: "DAX30, Germany",
        HX_IBEX: "IBEX35, Spain",
        HX_SPX: "S&P 500 index",
        HX_FTSE: "FTSE 100",
        HX_KS11: "KOSPI Korea",
        HX_NZ50: "New Zealand 50",
        HX_PSI20: "Portugal PSI20",
        hf_ZSD: "London Zinc",
        hf_NID: "London Nickel",
        hf_PBD: "London lead",
        hf_SND: "London tin",
        hf_XAU: "London Gold (Spot Gold)",
        hf_XAG: "London Silver (Spot Silver)",
        hf_XPT: "Futuri su platino",
        hf_XPD: "Futuri Palladio",
        hf_CT: "Cotone USA",
        hf_SM: "Farina di soia americana",
        hf_BO: "Olio di soia americano",
        HX_SENSEX: "SENSEX, Mumbai, India",
        HX_KLSE: "FTSE Malaysia KLCI",
        HX_KSE100: "Karachi, Pakistan",
        HX_FCHI: "CAC40, France",
        HX_SX5E: "European Snooker 50",
        HX_ICEXI: "ICEX, Iceland",
        HX_ASE: "ASE Athens, Greece",
        HX_OMXC20: "OMX Copenhagen 20",
        HX_OSEBX: "OSEBX, Norway",
        HX_OMXSPI: "OMXSPI, Sweden",
        HX_AMD: "Chaowei Semiconductor",
        HX_DIS: "Disney (name)",
        HX_MELI: "MercadoLibre Inc",
        HX_PSX: "Phillips 66",
        HX_TRUE: "TrueCar Inc",
        HX_SDRL: "Seadrill Ltd",
        HX_MA: "MasterCard (brand)",
        HX_NVDA: "NVIDIA, computer graphics card company",
        HX_INTC: "Intel",
        HX_GOGO: "Gogo Inc",
        HX_STX: "Seagate Technology",
        usdcusdt: "USDC",
        filusdt: "FIL",
        maticusdt: "MATIC",
        linkusdt: "LINK",
        avaxusdt: "AVAX",
        atomusdt: "AstroCoin",
        icpusdt: "ICP",
        uniusdt: "UNI",
    },
    currenctType: {
        title: "Seleziona una valuta",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'Dollar',
        currenctType15: 'Hong Kong currency',
        currenctType16: 'Taiwan currency',
        currenctType17: 'Ringgit',
        currenctType18: 'Singapore dollar',
        currenctType19: 'Yen',
        currenctType20: 'Euro',
        currenctType21: 'Dollar A',
        currenctType22: 'Rupiah',
        currenctType23: 'Pound',
        currenctType24: 'Thai baht',
        currenctType25: 'Dollar',
        currenctType26: 'VND',
        currenctType27: 'Korean won',
        currenctType28: 'MOP',
    },
    addnew3: {
        direction: "Direzione",
        sell_price: "Prezzo di vendita",
        number: "Quantità",
        type: "Tempi di consegna",
        buy_price: "Prezzo di acquisto",
        profit: "P/L",
        sell_time: "Tempo di vendita",
    },
    addnew2: {
        address: {
            second_name: "Katakana",
            account_type: "Tipo di conto",
            ifsc_code: "IFSC CODE",
            title: "Indirizzo del portafoglio",
            account_number: "Numero di conto",
            add_btn: "Aggiungi indirizzo",
            label_type: "Seleziona tipo",
            place_type: "Seleziona il tipo",
            label_number: "Conto",
            place_number: "Inserisci il numero di conto",
            wallet_address: 'Indirizzo del portafoglio',
            bank_name: 'NOME DELLA BANCA',
            bank_address: "Indirizzo bancario",
            name: "Nome",
            payee_address: 'Indirizzo del beneficiario',
            swift_code: "Codice rapido/rapido/BIC",
            aba: "ABA",
            branch_number: "Nome del ramo",
            branch_code: "CODICE BRANCH",
            bank_code: "Codice bancario",
            phone: "Numero di cellulare",
            iban: "IBAN",
            sort_code: "Codice di ordinamento",
            place_inner: "Inserisci il contenuto",
        },
    },
    addnew: {
        realFirst: "Effettua prima l'autenticazione dell'identità",
        payFirst: "Imposta prima la password della transazione",
        password1: "Cambia password di accesso",
        password2: "Cambia la password di prelievo",
        paypassTit1: "Si prega di impostare la password di prelievo",
        oldPass: "Vecchia password",
        allView: "Tutti letti",
        confirmOrder: "confermare un ordine",
        name: "Nome",
        fee: "Tassa",
        orderCycle: "Tempistica",
        loan: "Prestito",
        loanTit: "Prestiti di assistenza",
        loanHostory: "Registri dei prestiti",
        labelProductTitle: "Nome del prodotto",
        immediateRepayment: "Rimborso immediato",
        loadAmount: "Importo previsto del prestito",
        RepaymentCycle: "Ciclo di rimborso dei prestiti",
        day: "Giorno",
        dailyRate: "Tariffa giornaliera",
        repaymentMethod: "Metodo di rimborso",
        lendingCompany: "Società di prestito",
        loanTip: "Credito di credito (si prega di assicurarsi che l'immagine sia chiara e visibile)",
        loanTip1: "Carica certificato di proprietà",
        loanTip2: "Prova del reddito (rapporto di lavoro)",
        loanTip3: "Dettagli dell'estratto conto bancario",
        loanTip4: "Carica una foto della parte anteriore della tua carta d'identità",
        interest: "Interessi",
        repaid: "Rimborso",
        unpaid: "Non pagato",
        tab1: "Cripto",
        tab2: "Contratto",
        tab3: "Opzione",
        available: "Limite disponibile",
        pending: "Blocca",
        equivalent: "Equivalente",
        fundTransfer: "Trasferimento di fondi",
        flashExchange: "Scambio flash",
        select_breed: "Seleziona valuta",
        from: "Da",
        to: "A",
        transferQuantity: "Quantità di trasferimento",
        repaymentMethod1: "Un rimborso alla scadenza"
    },
    params: {
        product_foreign: "Forex",
        product_shop: "Materie prime",
        product_number: "Indici",
        product_stock: "Azioni",
        product_coin: "Cripto",
        billTypes: [
            { v: 101, name: 'Ricarica' },
            { v: 102, name: 'Deposito' },
            { v: 103, name: 'congelare' },
            { v: 104, name: 'scongelamento' },
            { v: 105, name: 'Deposito' },
            { v: 106, name: 'Deposito' },
            { v: 201, name: 'In attesa' },
            { v: 202, name: 'Ritiro' },
            { v: 203, name: 'Successo' },
            { v: 204, name: 'Ritiro fallito' },
            { v: 205, name: 'Tassa di gestione dei prelievi' },
            { v: 206, name: 'Trasferimento fuori' },
            { v: 207, name: 'Trasferimento in' },
            { v: 208, name: 'Trasferimento di monete' },
            { v: 209, name: 'Trasferimento di monete in' },
            { v: 301, name: 'Tassa di gestione del contratto' },
            { v: 302, name: 'Redditi contrattuali' },
            { v: 303, name: 'Perdite contrattuali' },
            { v: 304, name: 'Margine contrattuale' },
            { v: 305, name: 'Rimborso del deposito contrattuale' },
            { v: 311, name: 'Opzioni di acquisto' },
            { v: 312, name: 'Restituisce opzione' },
            { v: 313, name: 'Rimborso dell opzione' },
            { v: 314, name: 'Tassa di gestione delle opzioni' },
            { v: 315, name: 'Congelamento dell acquisto di valuta' },
            { v: 316, name: 'deduzione dell acquisto di valuta' },
            { v: 317, name: 'Rendimento dell acquisto di monete' },
            { v: 318, name: 'Acquisto di monete ricevute' },
            { v: 319, name: 'Acquisto di monete ricevute' },
            { v: 320, name: 'Congelamento delle vendite valutarie' },
            { v: 321, name: 'detrazione delle vendite in valuta' },
            { v: 322, name: 'Rendimento delle vendite di monete' },
            { v: 323, name: 'Monete vendute sul conto' },
            { v: 324, name: 'Monete vendute sul conto' },
            { v: 325, name: 'Tassa di gestione della valuta' },
            { v: 401, name: 'Aggiunta di macchine minerarie' },
            { v: 402, name: 'Ritorno delle macchine minerarie' },
            { v: 403, name: 'Entrate delle macchine minerarie' },
            { v: 404, name: 'Uscita della macchina mineraria' },
            { v: 405, name: 'Tassa di gestione delle uscite delle macchine minerarie' },
            { v: 411, name: 'Redditi da indebitamento' },
            { v: 412, name: 'Costo di chiusura' },
            { v: 413, name: 'Rimborso riuscito' },
        ],
        transType0: "Merci spot",
        transType1: "Contratto",
        directionType0: "Compra",
        directionType1: "Vendita",
        lockStateType0: "In esecuzione",
        lockStateType1: "Terminato",
        lockStateType2: "Annullato",
    },
    lockming: {
        profit: "Recenti (guadagni giornalieri)",
        cycle: "ciclo finanziario",
        limit: "Limite unico",
        dividend_time: "Tempo di pagamento dei dividendi",
        every_day: "ogni giorno",
        funds: "Fondi fiduciari",
        back: "Restituzione alla scadenza",
        ransom: "Rimborso anticipato",
        label_amount: "Importo dell'impegno",
        place_amount: "Inserisci,,,",
        available: "disponibile",
        expect_income: "Reddito atteso",
        title: "Miniera chiusa",
        tip: "Estrazione mineraria per reddito",
        label_today_income: "Stima degli utili oggi",
        label_total_income: "Reddito cumulativo",
        label_order: "Ordini in deposito",
        label_number: "Importo unico",
        daily_return: "Rendimento giornaliero",
        subscribe: "subscribe",
        position: "Posizione",
        label_state: "Status",
        subscribe_time: "subscription_time",
        expiration_time: "tempo di scadenza",
        income: "reddito",
        errorTip1: "Inserisci l'importo",
        tip_tit: "Sei sicuro di voler uscire presto",
    },
    footer: {
        nav1: "Home",
        nav2: "Commercio",
        nav3: "Posizioni",
        nav4: "Hub",
        kline_set1: "Definizione del mercato",
        kline_set2: "Impostazioni transazione",
    },
    login: {
        login: "Login",
        register: "Registrati",
        type1: "Numero di telefono",
        type2: "Email",
        login_btn: "Accedi",
        register_btn: "Registrati ora",
        psd_login: "Accesso password",
        verify_login: "Accesso al codice di verifica",
        forget: "Password dimenticata?",
        no_account: "Nessun conto?",
        have_account: "Conto esistente",
    },
    home: {
        home: {
            nav1: "Classifica giornaliera",
            nav2: "Basics",
            nav3: "Su di noi",
            nav4: "Rinvio",
            most_follow: "Popolare",
            news_type1: "Straters",
            news_type2: "Notizie",
            news_type3: "Calendario",
            news_tab1: "All",
            news_tab2: "Forex",
            news_tab3: "Azioni",
            news_tab4: "Commodity",
            news_tab5: "Indici",
            news_tab6: "Dati",
            news_tab7: "Evento",
            news_tab8: "Vacanze",
            label_previous: "Precedente",
            label_predictive: "Predittivo",
            label_announced: "Annunciato ",
        },
        kilne: {
            label_bp: "Chiudi",
            label_sp: "Apri",
            label_height: "Altezza",
            label_low: "Minimo ",
            tip: "Ciao, sono l'assistente varietà StratTradeGPT",
            row1_tit: "Strategia",
            row1_rinei: "Intraday",
            row1_short: "A breve termine",
            row1_middle: "Periodo medio",
            row1_time: "Tempo di rilascio",
            row1_tit2: "Strategia di trading",
            data_sources: "Fonti di dati",
            row2_tit1: "Dati",
            row2_tit2: "Informazioni",
            row2_label_sell: "Venditore",
            row2_label_buy: "Acquirente",
            tip2: "Solo per riferimento, non come posizione della nostra azienda",
            tip3: "Aggiornamento ogni 15 minuti",
            row2_tit3: "Variazioni dei prezzi",
            minute: "Minuto",
            today: "Oggi",
            right_tit7: "Variazione dei prezzi",
            now_price: "Prezzo corrente",
            right_low_price: "Low",
            right_height_price: "Altezza",
            details: "Dettagli",
            right_label1: "Numero di singole operazioni",
            right_label2: "Leva massima",
            right_label3: "Dimensione del contratto",
            right_label4: "Quantità massima totale della posizione",
            right_label5: "Differenza di prezzo variabile",
            right_label6: "Tassa di pernottamento",
            hand: "Hand",
            right_sell: "Vendi",
            right_buy: "Compra",
            right_collection_time: "Tempo di raccolta",
            right_tip3: "Raccogli dall'intera dimensione della transazione",
            right_label9: "Sessione di trading",
            right_label10: "Periodo attuale",
            right_label11: "Periodo successivo",
        },
        placeorder: {
            direction_buyPrice: "Tasso d'acquisto",
            direction_sellPrice: "Tasso di vendita",
            direction_sell: "Vendi",
            direction_buy: "Compra",
            market_price: "Ordine di mercato",
            check_price: "Registrazione",
            sell_tip: "Vendere quando il prezzo soddisfa le condizioni",
            buy_tip: "Acquista quando il prezzo soddisfa le condizioni",
            trade_number: "Quantità di transazione",
            hand: "Hand",
            select_lever: "Leva di selezione",
            right_tip: "Deposito richiesto",
            balance: "Disponibile",
            label_stop_win_price: "Stop surplus",
            label_stop_lose_price: "Stop loss",
            expected_profit_loss: "Utile e perdite attese",
            trailing_stop: "Fermata trailing",
        },
        ranking: {
            tit: "Classifica giornaliera su e giù",
            p: "Cattura gli hotspot e coglie le opportunità",
            renew: "Aggiornamento On",
            growth_chart: "Grafico della crescita",
            drop_list: "Elenco a discesa",
            show_closed_market: "Mostra marchi fuori mercato",
            filter1: "Intero",
            filter2: "Estero ",
            filter3: "Commodity",
            filter4: "Certificato di condivisione",
            filter5: "Indice",
            filter6: "Cripto",
            td1: "Classifica",
            td2: "Tendenza",
            td3: "Aumento giornaliero/prezzo di vendita",
        },
        remind: {
            now_sell_price: "Prezzo di vendita corrente",
            direction_buyPrice: "Tasso d'acquisto",
            direction_sellPrice: "Tasso di vendita",
            direction_sell: "Vendi",
            direction_buy: "Compra",
            equal: "Uguale",
            p: "Ricordami quando vengono raggiunti i seguenti prezzi",
            btn1: "Imposta promemoria",
            list_tit: "Elenco promemoria",
        },
    },
    my: {
        report: {
            tit: "Relazione contabile",
            tab1: "rapporto",
            tab2: "Dichiarazione giornaliera",
            tab3: "estratto conto mensile",
            history_tit: "Rapporto sullo storico delle transazioni",
            account_tit: "Relazione contabile",
            filter: "schermo",
            tip1: "Seleziona l'intervallo di date appropriato per esportare il report sullo storico delle transazioni.",
            tip2: "Tempo personalizzato",
            email_send: "Invia via e-mail",
        },
        accountMsg: {
            tit: "Informazioni sul conto",
            label1: "Informazioni personali",
        },
        bill: {
            tit: "Dettagli sul flusso di capitale",
            all: "Intero",
            time: "Time",
        },
        feedback: {
            tit: "Raccomandazioni funzionali",
        },
        identity: {
            tit: "Profilo completo",
            type1: "ID nazionale",
            type2: "Patente di guida",
            type3: "Passaporto",
            tip: "Confermo che tutte le informazioni e i documenti forniti sono completi, veritieri e accurati",
        },
        my: {
            is: "Stop",
            no: "Non verificato",
            verify: "Verificato",
            id_verify: "Verifica dell'identità",
            verify_tip: "Verifica completa dell'identità per depositare e iniziare a fare trading",
            no_verify: "Verifica ora",
            balance: "Disponibile",
            profit_loss: "Utile e perdite",
            marginLevel: "Livello margine",
            btn1: "Passa a un account reale",
            deposit_withdraw_record: "Registri di deposito e prelievo",
            capital_flow_details: "Dettagli sul flusso di capitale",
            welfare_center: "Centro sociale",
            tip1: "Tu hai",
            tip2: "Ricompensa da rivendicare",
            feedback: "Raccomandazioni funzionali",
            report: "Relazione contabile",
            setting: "Impostazioni",
        },
        news: {
            tit: "Avviso",
        },
        record: {
            tit: "Registri di deposito e prelievo",
            cycle: "Ciclo",
        },
        share: {
            tit: "Invita amici",
            invite_success: "Amici invitati con successo",
            tip1: "Ricompense in denaro",
            tip2: "Invita gli amici a prendere il più alto",
            tip3: "Invita gli amici a raccogliere denaro",
        },
        welfare: {
            tit: "Centro sociale",
            tab1: "Rotolo di carte",
            tab2: "Attività",
            history_record: "Storia",
            period_validity: "Periodo di validità",
        },
        wallet: {
            tit: "Portafoglio",
            addNew: "Aggiungi un nuovo portafoglio",
        },
        withdraw: {
            tit: "Prelievo",
            addNew: "Aggiungi un nuovo portafoglio",
        },
    },
    order: {
        demo_account: "Conto simulato",
        real_account: "Conto Live",
        tip: "Dopo l'apertura, l'oro può essere depositato per le transazioni",
        tab1: "Posizione",
        tab2: "Registrazione",
        tab3: "Storia",
        list_empty: "Attualmente non ci sono ordini pendenti",
        start_trade: "Inizia a fare trading",
        hand_tip: "Intervallo di ingresso",
        kline: {
            direction_sell: "Vendi",
            direction_buy: "Compra",
            open_price: "Prezzo aperto",
            now_price: "Prezzo corrente",
            trade_account: "Conto di trading",
            demo_account: "Conto simulato",
            order_id: "ID ordine",
            open_time: "Orario aperto",
            stop_profit: "Stop surplus",
            stop_loss: "Stop loss",
            trailing_stop: "Fermata trailing",
            float_profit_loss: "P/L galleggiante",
            overnight_fee: "Tassa di pernottamento",
            margin: "Margine",
            maintainsMargin: "Margine di manutenzione",
            open_value: "Valore di apertura",
            now_value: "Valore corrente",
            notset: "Non impostato",
            close_position: "Posizione di chiusura",
            close_number: "Quantità normale",
            onclick_close: "Posizione di chiusura con un clic",
            btn: "Conferma posizione di chiusura",
            balance: "Disponibile",
            profit_loss: "Utile e perdite",
            netValue: "Valore netto",
            marginLevel: "Livello margine",
        },
        modify: {
            tit: "Modifica ordine posizione",
            expected_profit_loss: "Utile e perdite attese",
        },
    },
    setting: {
        security: {
            modify_password_tit: "Imposta una nuova password",
            verify_email_tit: "Verifica email",
            verify_password_tit: "Verifica la password",
            verify_password_tip: "Inserisci la tua password di accesso StratTrade per continuare",
            verify_phone_tit: "Verifica il numero di telefono",
            tit: "Sicurezza",
            tip: "Per proteggere la sicurezza del tuo account e accettare le nostre notifiche di servizio, si consiglia di abilitare almeno un tipo di autenticazione.",
            label1: "Cambia password",
            label2: "Annulla iscrizione",
            label3: "Verifica doppia",
            label4: "Gestione delle attrezzature",
            tip2: "L'accesso al nuovo dispositivo richiede la verifica",
        },
        tradenews: {
            tit: "Guida alle transazioni",
            tab1: "Avvio rapido",
            tab2: "Apprendimento avanzato",
            tab3: "Aiuto",
            tab4: "Informazioni su StratTrade",
            viewMore: "Visualizza di più",

            details: {
                tit: "Introduzione ai cambi",
                tip1: "Durata stimata dell'apprendimento",
                tip2: "Minuto",
            },
            tab1Row: {
                tit: "Questa è una classe per imparare rapidamente a fare trading con StratTrade",
                tit1: "Come viene fatto il trading su StratTrade?",
                tit2: "Come aprire un conto reale？",
                tit3: "Come aprire una posizione per il trading？",
                tit4: "Come impostare stop profit e stop loss？",
                tit5: "Come chiudere una posizione？",
                p1: "È possibile negoziare l'oggetto comprandolo o vendendolo. Aprire una posizione in StratTrade significa che siete interessati alla",
                p2: "Trading basato sulle fluttuazioni dei prezzi del prodotto target",
                p3: "Ad esempio, se ti aspetti che il prezzo dell'oro aumenti, acquista il prodotto; Al contrario, se prevedi che il prezzo dell'oro diminuirà, allora vendi il prodotto. Se il futuro soddisfa le aspettative, è possibile",
                p4: "Ne traggono profitto",
                p5: "Inoltre, StratTrade fornisce 'leveraged trading'. Puoi usare la leva finanziaria per scambiare più obiettivi con la stessa quantità di fondi.",
                p6: "La leva finanziaria può amplificare i profitti e anche amplificare le perdite",
                p7: "Questo è noto anche come 'margin trading'. Quando apri una posizione, il tuo conto StratTrade detrarrà un certo saldo come",
                p8: "MARGINE INIZIALE",
                p9: "Selezionare i prodotti e le direzioni di acquisto e vendita",
                p10: "Operazione",
                p11: "Clicca su 'Acquista/Vendi' per completare l'ordine",
                p12: "Impostare 'stop profit' o 'stop loss' può aiutarti",
                p13: "Bloccare tempestivamente i profitti e limitare le perdite",
                p14: "Puoi cliccare sulla pagina di apertura riuscita",
                p15: "Stop profit/stop loss",
                p16: "Configuralo, oppure puoi fare clic sul pulsante 'Modifica' per l'ordine non bilanciato per fermare profitti e perdite",
                p17: "Imposta o annulla",
                p18: "Puoi visualizzare profitti e perdite nelle 'posizioni chiuse'",
            },
            tab3Row: {
                h1: "Benvenuti a StratTrade",
                h2: "Aiuto",
                tit0: "Tutte le questioni",
                tit1: "Conto simulato",
                tit2: "Aprire un conto",
                tit3: "Entrate e uscite",
                tit4: "Impostazioni della piattaforma",
                tit5: "Operazioni di transazione",
                tit6: "Commissioni e oneri",
                tit7: "Sicurezza finanziaria e regolamenti",
                tit1Tit1: "StratTrade fornisce conti simulati",
                tit1Cont1: "<p>StratTrade fornisce account simulati per l'operatività dei clienti, consentendo di utilizzare la nostra piattaforma online e le applicazioni mobili per le transazioni e familiarizzare con le operazioni della piattaforma senza rischi</p>",
                tit1Tit2: "Come creare un account simulato?",
                tit1Cont2: "<p>Devi solo cliccare qui per inserire il tuo indirizzo e-mail o numero di telefono e impostare la tua password personale per completare la registrazione e ottenere un account simulato </p>",
                tit1Tit3: "Qual è l'uso di conti simulati?",
                tit1Cont3: "<p>I conti simulati sono per lo più gli stessi dei conti reali in termini di interfaccia di transazione, dati e operazioni. C'è un fondo virtuale di $50000 nell'account simulato, con l'obiettivo di familiarizzare i clienti con le varie funzioni della piattaforma attraverso operazioni simulate senza alcun rischio finanziario</p>",
                tit1Tit4: "Come iniettare fondi in un conto simulato?",
                tit1Cont4: "<p>Non è necessario iniettare fondi nel conto di simulazione. Una volta registrato con successo il conto di simulazione, ci sarà un totale di 50000 USD o AUD nel conto</p><p>Quando il valore netto nel conto simulato è inferiore a $200 USD o AUD, il sistema riempirà automaticamente i fondi del tuo conto a $50000 USD o AUD</p>",
                tit1Tit5: "L'account simulato ha un periodo di utilizzo",
                tit1Cont5: "<p>Il periodo di validità dell'account simulato è di 90 giorni. Se non apri un conto reale dopo 90 giorni, il conto simulato verrà congelato e non potrà essere scambiato e verrà sciolto quando apri un conto reale.</ p><p>Se apri un conto reale entro 90 giorni, l'account simulato sarà valido per molto tempo. Anche dopo aver aperto un conto reale, puoi continuare a operare su un conto simulato.</p>",
                tit2Tit1: "Quanti tipi di conto offre StratTrade?",
                tit2Cont1: "<p>Forniamo solo conti di trading standard e professionali per individui e non supportiamo l'apertura di conti aziendali o comuni</p>",
                tit2Tit2: "In quale valuta posso aprire un conto di trading?",
                tit2Cont3: "<p>Quando apri un conto, il sistema determinerà automaticamente la valuta di regolamento in base alla tua regione e non sarà modificabile</p>",
                tit2Tit4: "Come aprire un conto reale?",
                tit2Cont4: "<p>Segui i passaggi seguenti per aprire un conto reale</p><p>1. Entra nella pagina di registrazione StratTrade e segui le istruzioni per registrare un numero di telefono email, impostare una password di accesso e crearla automaticamente dopo aver inviato la conferma</p><p></p><p></p>",
                tit3Tit1: "Come posso effettuare un deposito?",
                tit3Cont1: "<p>StratTrade StratTrade offre diversi metodi di deposito per i clienti tra cui scegliere, tra cui ma non possono essere limitati a quanto segue:</p><p>1.Visa/Mastercard - Di solito ricevuto immediatamente</p><p>2.Il pagamento bancario online viene generalmente effettuato entro 1 giorno lavorativo in anticipo</p><p>3.Portafogli elettronici (come Skrill, Momo, Zalo. Touch'n GO, Boost, ecc.) - tipicamente ricevuta istantanea</p><p>4. Carta bancomat - solitamente ricevuta istantanea</p><p>5.QR mobile online banking - solitamente ricevuta istantanea</p>",
                tit3Tit2: "Posso utilizzare un conto bancario/carta intestata a terzi per depositare o prelevare fondi",
                tit3Cont2: "<p>StratTrade non accetta depositi di terze parti. Si prega di utilizzare il proprio conto bancario / carta a proprio nome per depositare o prelevare fondi. Se si utilizza il nome di qualcun altro o conto bancario / carta della società per depositare denaro, i fondi saranno restituiti</p>",
                tit3Tit3: "Come richiedere il prelievo dal conto StratTrade?",
                tit3Cont3: "<p>Dopo aver effettuato l'accesso alla piattaforma del sito web, è possibile fare clic su 'Cash Out', selezionare un conto bancario o Visa/Mastercard, compilare l'importo di prelievo e quindi premere 'Invia '. Dopo aver confermato che le informazioni sono corrette, StratTrade elaborerà la tua domanda entro 1-2 giorni lavorativi</p>",
                tit4Tit1: "Quante volte StratTrade offre trading con leva finanziaria?",
                tit4Cont1: "<p>Il leverage ratio fornito dalla piattaforma StratTrade è impostato in base alle normative delle autorità di regolamentazione e varia anche la leva massima per i diversi prodotti. Si prega di accedere alla piattaforma di trading per i dettagli..</p>",
                tit4Tit2: "Come cambiare la mia password?",
                tit4Cont2: "<p>Dopo aver effettuato l'accesso alla piattaforma, è possibile scegliere [Altro] - [Impostazioni di sicurezza] - T Cambia password] per ripristinare la nuova password</p>",
                tit4Tit3: "Come accedere alla piattaforma di trading StratTrade?",
                tit4Cont3: "<p>App: Vai su 'My' e clicca su 'Registrazione Login' nell'angolo in alto a sinistra per accedere alla pagina di login.</ p>Web: Clicca su 'Login' nell'angolo in alto a destra del sito ufficiale di StratTrade per accedere alla pagina di login.<p></p><p></p><p></p><p></p>",
                tit4Tit4: "Quali sono i metodi per i promemoria della piattaforma?",
                tit4Cont4: "<p>Avvisi StratTrade tramite e-mail, SMS e piattaforma push.</p>",
                tit5Tit1: "Che differenza di prezzo offre StratTrade?",
                tit5Cont1: "<p>Facciamo pagare una differenza di prezzo bassa e non applichiamo alcuna commissione. La differenza di prezzo è fluttuante e la differenza di prezzo effettiva addebitata dipenderà dalla varietà che stai negoziando e le informazioni specifiche saranno visualizzate sulla piattaforma di trading in tempo reale.</p>",
                tit5Tit2: "Come stabilire un listino prezzi di mercato?",
                tit5Cont2: "<p>Cliccando su 'Trade' verranno visualizzati tutti i prodotti che la piattaforma può offrire per il trading. Puoi inserire il codice della varietà o il nome nella casella di immissione a destra per cercare, selezionare un prodotto e fare clic su 'Acquista/Vendi' SEL/ACQUISTA' per aprire una finestra di trading. Nella finestra di trading, puoi vedere il prezzo corrente e il margine richiesto previsto. È possibile regolare manualmente il numero di posizioni aperte e impostare stop profit e stop loss per il controllo del rischio</p>",
                tit5Tit3: "Come creare un modulo di registrazione?",
                tit5Cont3: "<p>Selezionando un prodotto in [Trade] e cliccando su [Vendi/ACQUISTA] si aprirà una finestra di trading</p>",
                tit5Tit4: "Come chiudere una posizione?",
                tit5Cont4: "<p>Per chiudere una posizione, fare clic su \"Apri\" sulla piattaforma in \"Posizione\", quindi selezionare la posizione che si desidera chiudere, quindi fare clic sul pulsante \"Chiudi\" sul lato destro</p>",
                tit5Tit5: "Come modificare o eliminare un ordine in sospeso?",
                tit5Cont5: "<p>Per modificare o eliminare gli ordini, fare clic su ''Ordini'' sulla piattaforma di visualizzazione nella sezione ''Posizione'' e quindi selezionare gli ordini che si desidera modificare o eliminare in corso</p>",
                tit6Tit1: "Verrà addebitata una commissione di gestione per l'utilizzo della piattaforma di trading?",
                tit6Cont1: "<p>StratTrade è una piattaforma di trading completamente priva di commissioni. Facciamo pagare una differenza di prezzo bassa e, a seconda della situazione specifica della transazione, la piattaforma potrebbe incorrere in commissioni aggiuntive, come gli interessi overnight</p>",
                tit6Tit2: "C'è qualche commissione per il deposito?",
                tit6Cont2: "<p>Non addebiteremo alcuna commissione relativa al deposito ai clienti, ma il pagamento o la banca intermediaria potrebbero addebitare commissioni di gestione. Ti consigliamo di chiedere alle banche competenti se ti hanno addebitato delle commissioni.</p>",
                tit6Tit3: "C'è una commissione per il prelievo?",
                tit6Cont3: "<p>StratTrade non addebiterà ai clienti alcuna commissione relativa al prelievo di fondi a meno che l'importo prelevato sia inferiore al requisito minimo o superi il numero massimo di prelievi gratuiti per il mese. Per informazioni più dettagliate, si prega di fare riferimento alla nostra politica di prelievo.</p>",
                tit7Tit1: "I fondi che deposito sul mio conto StratTrade saranno utilizzati per altri scopi?",
                tit7Cont1: "<p>No. I depositi dei clienti al dettaglio vengono depositati separatamente in conti fiduciari quando necessario in conformità con le normative vigenti. Nel depositare fondi nei conti dei clienti o prelevare fondi dai conti dei clienti o effettuare pagamenti, StratTrade rispetta rigorosamente le normative in materia di pagamenti dei clienti.</p>",
                tit7Tit2: "Se StratTrade fallisce o è in debito, anche i miei fondi saranno interessati?",
                tit7Cont2: "<p>I depositi dei clienti al dettaglio vengono depositati separatamente in conti fiduciari quando necessario in conformità con le normative normative. Se StratTrade fallisce, viene revocata la licenza o non è in grado di continuare ad operare, i fondi dei clienti possono ancora essere messi in quarantena e soggetti alla supervisione delle normative sui fondi dei clienti nelle leggi regolamentari</p>",
                tit7Tit3: "StratTrade rispetta le normative legali?",
                tit7Cont3: "<p>La nostra società è pienamente conforme alle leggi aziendali pertinenti e alle leggi e ai regolamenti normativi finanziari ed è un fornitore di servizi finanziari autorizzato e rigorosamente regolamentato dalle agenzie di regolamentazione competenti. La nostra azienda fornisce una protezione completa e sufficiente per la sicurezza finanziaria dei clienti in conformità con severi requisiti normativi.</p>",
                tit7Tit4: "Le informazioni personali del cliente sono sicure?",
                tit7Cont4: "<p>StratTrade rispetta rigorosamente le disposizioni del Regolamento sulla protezione dei dati personali e i dati personali dei clienti non saranno mai divulgati. L'azienda si assicurerà che i dipendenti rispettino i severi standard di sicurezza e riservatezza stabiliti dalle leggi e dai regolamenti e prestino particolare attenzione alle modalità di riservatezza e utilizzo dei dati dei clienti. Per i dettagli, si prega di fare riferimento ai documenti legali della nostra azienda.</p>",
            },
            tab4Row: {
                span: "Q metodo di verifica",
                p1: "StratTrade è la risposta per coloro che cercano piattaforme di derivati crypto di livello professionale. Il nostro obiettivo è quello di creare un mercato efficiente ed equo che colleghi trader di vari background e stili di trading. Elevata capacità del motore di matching, bassa latenza, gestione avanzata del rischio e elevata liquidità rendono StratTrade un partecipante unico nel mercato.",
                p2: "L'obiettivo iniziale di StratTrade era quello di creare il primo exchange di opzioni di criptovaluta al mondo. Nonostante fosse un compito molto impegnativo, il team ha completato il lavoro di sviluppo del sistema in poco più di due anni. Nel giugno 2016, StratTrade è stato lanciato ufficialmente.",
                p3: "StratTrade è uno scambio di opzioni e future di criptovalute con sede a Panama City, Panama. StratTrade era originariamente una piattaforma di trading BTC completamente dedicata, tuttavia, da allora, sono stati aggiunti contratti ETH e arriveranno anche altri contratti in valuta.",
                p4: "Attualmente, i nostri clienti possono negoziare contratti perpetui, contratti futures e contratti di opzioni. StratTrade si è sviluppata rapidamente ed è uno dei migliori exchange che offre futures di criptovalute e contratti perpetui. Inoltre, StratTrade rimane uno dei principali exchange che offre opzioni di transazione in contanti in stile europeo e continua a stabilire standard per il settore.",
                p5: "StratTrade Holding è autorizzata e regolamentata dalla Cayman Islands Monetary Authority (CIMA) con un numero di licenza SIB 1612446. Per maggiori dettagli sulla targa Cayman, visitare il sito ufficiale della CIMA all'indirizzo www.cima.ky（",
                p6: ") Conduci una domanda. StratTradeGlobal detiene una licenza di servizi finanziari (AFSL 398528) autorizzata dalla Australian Securities and Investment Commission (ASIC). Per maggiori dettagli sulla targa australiana, visitare il sito ufficiale ASIC www.asic.gov.au (",
                p7: ") Conduci una domanda. StratTrade International è autorizzata e regolamentata dalla Mauritius Financial Services Commission (FSC) con un numero di licenza GB20025791. Per ulteriori informazioni sulla targa Mauritius, visitare il sito ufficiale della FSC www.fscmauricius.org(",
                p8: ")Conduci una domanda. Tutte le operazioni commerciali di StratTrade sono condotte sotto stretta supervisione e sono conformi a tutte le normative",
                p9: "Contesto",
                p10: "StratTrade è un team senior con una ricca esperienza e conoscenza nei settori del trading finanziario e fintech, fondato a Melbourne, Australia",
                p11: "La filosofia di StratTrade è quella di rendere le transazioni più semplici e amichevoli. La nostra strategia olistica e il nostro modello di business ci consentono di avere una comprensione completa e approfondita dello sviluppo del settore e della domanda del mercato, permettendoci di allocare risorse più mirate, innovare continuamente la tecnologia e ottimizzare l'efficienza, offrendo continuamente ai clienti esperienze di trading più convenienti e amichevoli",
                p12: "Perché scegliere",
                p13: "Condizioni di negoziazione a bassa soglia",
                p14: "Piattaforma di trading semplice e intuitiva",
                p15: "Costi di transazione competitivi",
                p16: "Superato dalle autorità del settore",
                p17: "Supporto online di alto livello",
                p18: "Protezione contro il bilanciamento negativo",
                p19: "Premi e onori",
                p20: "StratTrade è onorata di ricevere numerosi premi, tra cui il miglior broker multi asset del World Finance Awards, la migliore piattaforma di trading mobile di Global BrandsMagazine nella regione Asia-Pacifico e Global Fastest Growing Financial Technology Broker, tutti i quali confermano i continui sforzi e la dedizione del team.",
                p21: "Miglior broker multi asset",
                p22: "La nostra filosofia operativa",
                p23: "I nostri valori",
                p24: "Siamo una delle piattaforme di trading di criptovalute in più rapida crescita al mondo e crediamo che i nostri valori fondamentali siano la forza trainante di questo successo.",
                p25: "Onestà e integrità",
                p26: "la fiducia degli utenti è fondamentale; Pertanto, conduciamo la nostra attività con i più alti standard di integrità fornendo un sistema di gestione del rischio equo, trattando tutti i partecipanti al mercato in modo equo e soluzioni di sicurezza avanzate.",
                p27: "Qualità del servizio",
                p28: "Le transazioni in criptovalute sono un processo senza confini, in cui la qualità del servizio non viene compromessa. I clienti sono il nostro valore più importante; Pertanto, il nostro obiettivo è quello di fornire un'esperienza di trading ininterrotta e fluida. Il nostro obiettivo è quello di raggiungere questo obiettivo espandendo e migliorando continuamente la nostra architettura di sistema, riducendo al minimo i tempi di manutenzione e fornendo un servizio clienti tempestivo e multilingue.",
                p29: "crea",
                p30: "Nel frenetico ambiente delle criptovalute, l'innovazione è necessaria. StratTrade è una società basata su soluzioni volte a fornire la migliore esperienza di trading per milioni di utenti, radicata nella nostra architettura di sistema scalabile e ad alte prestazioni.",
                p31: "Perché scegliere i derivati？",
                p32: "Il trading di derivati ha molti vantaggi: minori costi di transazione, maggiore leva finanziaria, maggiore liquidità e più facile vendita allo scoperto.",
                p33: "Il trading di derivati consente anche strategie di trading più diverse. I trader possono utilizzare derivati per coprire il rischio, l'arbitraggio o il trading speculativo.",
                p34: "scienza e tecnologia",
                p35: "Per garantire un sistema ad alte prestazioni, è stato sviluppato per oltre due anni prima di andare in onda.",
                p36: "Il framework sviluppato per questa piattaforma è quello di garantire la capacità di elaborare un gran numero di richieste con bassa latenza. Abbiamo sviluppato un motore di corrispondenza unico specificamente per StratTrade e tutte le nostre tecnologie sono proprietarie.",
                p37: "Dal suo lancio, il nostro sistema di compensazione incrementale e gestione del rischio ha garantito zero perdite socializzate.",
                p38: "Noi di StratTrade crediamo nel futuro delle criptovalute. Il nostro obiettivo è continuare ad essere all'avanguardia nel mercato dei derivati delle criptovalute. Prevediamo che in futuro le criptovalute saranno utilizzate da tutti e scambiate da milioni di persone. Siamo pronti per il futuro, così come il nostro sistema.",
            },
        },
        language: {
            tit: "Scegli lingua",
        },
        market: {
            tit: "Definizione del mercato",
            tit1: "Visualizzazione dei prezzi",
            tit2: "Colore degli alti e bassi",
            buy_price: "Tasso d'acquisto",
            buy_price_tip: "Disegna una linea K al prezzo di acquisto",
            sell_price: "Tasso di vendita",
            sell_price_tip: "Disegnare la linea K al prezzo di vendita",
            color1: "Crescita verde e caduta rossa",
            color2: "Crescita rossa e caduta verde",
        },
        notice: {
            tit: "Impostazioni notifiche",
            tit1: "Apri canali di notifica",
            p1: "Si prega di aprire/verificare i seguenti canali di notifica, messaggi importanti non vengono persi!",
            message: "Messaggio di testo",
            push: "Spingi",
            verify: "Prova e verifica",
            verified: "Verificato",
            email: "E-mail",
            type1: "COMMERCIALIZZAZIONE",
            tit2: "Notifica di eventi di marketing",
            desc2: "Informazioni promozionali, attività operative e altri messaggi della piattaforma",
            tit3: "Istruzioni per l'uso",
            desc3: "Materiali di conoscenza ricchi e professionali",
            tit4: "Promemoria informazioni",
            desc4: "Dati finanziari recenti e notizie",
            tit5: "Promemoria intelligente delle fluttuazioni",
            desc5: "Comprese fluttuazioni di prezzo di varietà importanti, aiutandovi a cogliere rapidamente le opportunità",
            type2: "Classe di segnalazione",
            tit6: "Relazione contabile",
            desc6: "Il report del tuo account verrà inviato mensilmente nel seguente modo",
            cycle: "Ciclo",
            month: "Mensile",
        },
        protocol: {
            tit: "Dichiarazione e accordo",
            label1: "Informativa sulla privacy",
            label2: "Dichiarazione informativa sui prodotti",
            label3: "Accordo con il cliente",
            label4: "Dichiarazione informativa sui rischi",
        },
        setting: {
            tit: "Impostazioni",
            label1: "Impostazione di sicurezza",
            label2: "Definizione del mercato",
            label3: "Impostazioni transazione",
            label4: "Lingua",
            label5: "Modalità tema",
            label6: "impostazioni delle notifiche",
            label7: "Guida alle transazioni",
            label8: "Dichiarazione e accordo",
            label9: "Pulisci cache",
            label10: "Versione",
            logout: "Esci dal login",
        },
        theme: {
            tit: "Modalità tema",
            theme1: "Luce",
            theme2: "Scuro",
        },
        trade: {
            tit: "Impostazioni transazione",
            onclick_close: "Posizione di chiusura con un clic",
        }
    },
    trade: {
        fliter_type1: "Facoltativo",
        fliter_type2: "Mercato",
        place_search: "Inserisci nome/codice di ricerca della varietà",
        fliter_type3: "Popolare",
        fliter_type4: "Estero ",
        fliter_type5: "Merci",
        fliter_type6: "Condividi ",
        fliter_type7: "Indice",
        fliter_type8: "Cripto",
        tip1: "Verifica completa dell'identità per depositare e iniziare a fare trading",
        sell: "Vendi",
        buy: "Compra",
        add_optional: "Aggiungi selezione automatica",
        optional_recommend: "Raccomandazione di selezione",
        change_batch: "Cambia lotto",
        edit_tit: "Modifica selezione",
        breed: "Razza",
        topping: "Topping",
        sort: "Ordina",
        all: "Seleziona tutto",
    },
    table: {
        label_breed: "Razza",
        label_direction: "Direzione",
        label_price: "Prezzo",
        label_operate: "Operare",
        empty_tip1: "Nessun promemoria al momento",
        empty_tip2: "Nessun deposito registrato",
        empty_tip3: "Nessun registro di pagamento in contanti",
        label_amount: "Importo",
        label_type: "Tipi ",
        label_time: "Time",
        label_name: "Nome",
        label_email: "Email",
        label_address: "Paese",
        label_account: "Numero di conto di trading",
        label_currency: "Valuta di base",
        label_company: "Entità di servizio corrente",
    },
    form: {
        to: "To",
        start_time: "Ora di inizio",
        end_time: "Tempo di fine",
        week: "La scorsa settimana",
        month: "Il mese scorso",
        threeMonth: "Ultimi tre mesi",
        sixMonth: "Ultimi sei mesi",
        label_feedback: "Problemi e suggerimenti",
        place_feedback: "Inserisci la tua domanda o suggerimento",
        label_img: "Immagine",
        label_img_tip: "Facoltativo, fornire schermate del problema",
        feedback_tip: "Se avete domande urgenti, si prega di contattare",
        online: "Servizio online",
        label_cardNumber: "Numero identificativo",
        label_realName: "Nome",
        place_realName: "Inserisci il tuo nome",
        message_realName: "Inserisci il tuo nome",
        label_firstName: "Cognome",
        place_firstName: "Inserisci il tuo cognome",
        message_firstName: "Inserisci cognome",
        label_lastName: "Medio nome",
        place_lastName: "Facoltativo",
        message_lastName: "Inserisci un nome",
        label_birthday: "Data di nascita",
        place_birthday: "DD/MM/YYYY",
        message_birthday: "Inserisci la data di nascita",
        label_idType: "Tipo di verifica",
        tip_cardMain: "Scatta o carica una foto del FRONT della tua carta d'identità",
        tip_cardBack: "Scatta o carica una foto del retro della tua carta d'identità",
        tip_cardMain1: "Scattare o caricare una foto della PRIMA della patente di guida",
        tip_cardBack1: "Scattare o caricare una foto del POSTO della patente di guida",
        tip_cardMain2: "Scatta o carica una foto del tuo passaporto",
        tip_cardBack2: "Scatta o carica una foto del tuo passaporto",
        label_password: "Password",
        place_password: "Inserisci la password",
        message_password: "Inserisci la password",
        label_crmpsd: "Conferma password",
        place_crmpsd: "Inserisci nuovamente la password",
        message_crmpsd: "Inserisci nuovamente la password",
        label_email: "E-mail",
        place_email: "Indirizzo email",
        message_email: "Inserisci il tuo indirizzo email",
        label_captcha: " Codice di verifica",
        place_captcha: "Inserisci il codice di verifica",
        message_captcha: "Inserisci il codice di verifica",
        get_captcha: "Codice di verifica",
        label_phone: "Telefono",
        place_phone: "Inserisci un numero di telefono",
        message_phone: "Inserisci un numero di telefono",
        labelAmount: "Importo",
        placeAmount: "Inserisci l'importo",
        messageAmount: "Inserisci l'importo",
        labelWalletName: "Nome portafoglio",
        messageWalletName: "Inserisci il nome del portafoglio",
        placeWalletName: "Inserisci il nome del portafoglio",
        labelWalletAddress: "Indirizzo del portafoglio",
        messageWalletAddress: "Inserisci l'indirizzo del portafoglio",
        placeWalletAddress: "Inserisci l'indirizzo del portafoglio",
        labelWithdrawAccount: "Conto di prelievo",
        placeWithdrawAccount: "Conto di prelievo",
        messageWithdrawAccount: "Conto di prelievo",
        placeTimeLimit: "Inserisci timeLimit",
        messageTimeLimit: "Inserisci timeLimit",
    },
    city: {
        albania: "Albania",
        algeria: "Algeria",
        argentina: "Argentina",
        armenia: "Armenia",
        australia: "Australia",
        pakistan: "Pakistan",
        austria: "Austria",
        bahrain: "Bahrein",
        belgium: "Belgio",
        bosnia_and_Herzegovina: "Bosnia-Erzegovina",
        brazil: "Brasile",
        brunei: "Brunei",
        bulgaria: "Bulgaria",
        cambodia: "Cambodia",
        canada: "Canada",
        cameroon: "Camerun",
        chile: "Cile",
        colombia: "Colombia",
        costa_Rica: "Costa Rica",
        croatia: "Croazia",
        cyprus: "Cipro",
        czech_Republic: "Repubblica ceca",
        denmark: "Danimarca",
        dominican_Republic: "Repubblica Dominicana",
        egypt: "Egitto",
        estonia: "Estonia",
        ethiopia: "Etiopia",
        finland: "Finlandia",
        france: "Francia",
        georgia: "Georgia",
        germany: "Germania",
        ghana: "Ghana",
        greece: "Grecia",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong, Cina",
        hungary: "Ungheria",
        iceland: "Islanda",
        ireland: "Irlanda",
        italy: "Italia",
        india: "India",
        indonesia: "Indonesia",
        israel: "Israele",
        iran: "Iran",
        iraq: "Iraq",
        japan: "Giappone",
        kazakstan: "Kazakhstan",
        kenya: "Kenya",
        korea: "Corea",
        kuwait: "Kuwait",
        kyrgyzstan: "Kirghizistan",
        laos: "Laos",
        latvia: "Lettonia",
        lithuania: "Lituania",
        luxembourg: "Lussemburgo",
        macao_China: "Macao_Cina",
        macedonia: "Macedonia",
        malaysia: "Malaysia",
        malta: "Malta",
        mexico: "Messico",
        moldova: "Moldavia",
        monaco: "Monaco",
        mongolia: "Mongolia",
        montenegro: "Montenegro",
        morocco: "Morocco",
        myanmar: "Myanmar",
        netherlands: "Paesi Bassi",
        new_Zealand: " Nuova Zelanda",
        nepal: "Nepal",
        nigeria: "Nigeria",
        norway: "Norvegese",
        oman: "Oman",
        palestine: "Palestina",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Perù",
        philippines: "Filippine",
        poland: "Poland",
        portugal: "Portogallo",
        puerto_Rico: "Puerto Rico",
        qatar: "Qatar",
        romania: "Romania",
        russia: "Russia",
        republic_of_Trinidad_and_Tobago: "Repubblica di Trinidad e Tobago",
        rwanda: "Ruanda",
        saudi_Arabia: "Arabia Saudita",
        serbia: "Serbia",
        singapore: "Singapore",
        slovakia: "Slovacchia",
        slovenia: "Slovenia",
        south_Africa: "Sudafrica",
        spain: "Spagna",
        sri_Lanka: "Sri Lanka",
        sweden: "Svezia",
        switzerland: "Switzerland",
        taiwan_China: "Taiwan_Cina",
        tajikistan: "Tagikistan",
        tanzania: "Tanzania",
        thailand: "Thailandia",
        turkey: "Turkey",
        turkmenistan: "Turkmenistan",
        ukraine: "Ucraina",
        united_Arab_Emirates: "Emirati Arabi Uniti",
        united_Kingdom: "Regno Unito",
        united_States: " Stati Uniti",
        uzbekistan: "Uzbekistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Azerbaijan",
        bangladesh: "Bangladesh",
        belarus: "Bielorussia",
        belize: "Belize",
        benin: "Benin",
        bhutan: "Bhutan",
        bolivia: "Bolivia",
        botswana: "Botswana",
        british_Virgin_Islands: "Isole Vergini Britanniche",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Cape Verde",
        cayman_Islands: "Cayman Islands",
        central_African_Republic: "Repubblica Centrafricana",
        chad: "Chad",
        comoros: "Comore",
        the_Republic_of_the_Congo: " La_Repubblica_del_Congo",
        democratic_Republic_of_the_Congo: "Repubblica democratica del Congo",
        djibouti: "Gibuti",
        ecuador: "Ecuador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Guinea Equatoriale",
        eritrea: "Eritrea",
        fiji: "Figi",
        gabon: "Gabon",
        gambia: "Gambia",
        greenland: "Greenland",
        guatemala: "Guatemala",
        guinea: "Guinea",
        haiti: "Haiti",
        isle_of_Man: " Isola di Man",
        cote_d_Ivoire: "Costa d'Avorio",
        jamaica: "Giamaica",
        jordan: "Jordan",
        lebanon: "Libano",
        lesotho: "Lesotho",
        liberia: "Liberia",
        libya: "Libia",
        madagascar: "Madagascar",
        malawi: "Malawi",
        maldives: "Maldive",
        mali: "Mali",
        mauritania: "Mauritania",
        mauritius: "Mauritius",
        mozambique: "Mozambico",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "Repubblica_del_Niger",
        north_Korea: " Corea del Nord",
        reunion: "Reunion",
        san_Marino: "SAN Marino",
        senegal: "Senegal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalia",
        sudan: "Sudan",
        suriname: "Suriname",
        eswatini: "Eswatini",
        syria: "Siria",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunisia",
        united_States_Virgin_Islands: "Isole Vergini Americane",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Vaticano",
        yemen: "Yemen",
        yugoslavia: "Jugoslavia",
        zambia: "Zambia",
        zimbabwe: "Zimbabwe",
        china: "Cina",
    }
};
